import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GetCurrencies } from '../redux/actions/currency';
import { WhiteTopCurrencyBtn } from './WhiteTopCurrencyBtn';
import { WhiteTopCountryBtn } from './WhiteTopCountryBtn';

export const WhiteHeader = () => {
  const dispatch = useDispatch();
  const { currencies, message } = useSelector((state) => state.currency);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  useEffect(() => {
    dispatch(GetCurrencies());
  }, []);

  useEffect(() => {
    let current_currency_details = ls.get('current_currency', {
      decrypt: true,
    });
    if (current_currency_details) {
      let newObj = {};
      newObj['code'] = current_currency_details;

      currencies.currencies !== undefined &&
        currencies.currencies.length > 0 &&
        currencies.currencies
          .filter((currency) => currency.code === current_currency_details)
          .map((filteredCurrency) => {
            newObj['rate'] = parseFloat(filteredCurrency.rate);
          });
      setCurrentCurrency(newObj);
    }
  }, [currencies]);
  return (
    <>
      <header className="header-5">
        <nav
          id="navbar-main"
          className="fixed-top navbar bg-white navbar-expand-lg navbar-main"
        >
          <div className="container">
            <Link className="navbar-brand mr-lg-5" to="/">
              <img src="/assets/images/cloudhost-dark.png" alt="" />
            </Link>

            <div
              className="navbar-collapse collapse justify-content-around"
              id="navbar_global"
            >
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <Link to="">
                      <img
                        src="/assets/images/cloudhost-dark.png"
                        height="35"
                        alt="Logo Impact"
                      />
                    </Link>
                  </div>
                  <div className="col-6 collapse-close">
                    <Link
                      to=""
                      role="button"
                      className="fas fa-times"
                      data-toggle="collapse"
                      data-target="#navbar_global"
                      aria-controls="navbar_global"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    ></Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <WhiteTopCurrencyBtn
              currencies={currencies}
              current_currencies={currentCurrency}
            /> */}

            <WhiteTopCountryBtn />

            <div className="d-flex d-lg-none align-items-center">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar_global"
                aria-controls="navbar_global"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
