import axios from '../../axios';
import ls from 'localstorage-slim';
import { types } from './types';
import { setErrors, clearErrors } from './errors';
import { openLoader, closeLoader } from './loader';

let baseUrl = '';
generateBaseUrl();
function generateBaseUrl() {
  baseUrl = window.location.origin;
}

export const clearMessage = () => {
  return {
    type: types.CLEAR_MESSAGE,
  };
};

// Action for creating a user account
export const SignUp = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post('api/addClient', payload);

    if (response.data.success === true) {
      ls.set('isAuthenticated', response.data.success, { encrypt: true });
      ls.set('client', response.data, { encrypt: true });
    } else {
      ls.set('isAuthenticated', response.data.success, { encrypt: true });
    }
    dispatch({
      type: types.REGISTER_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for creating a user account
export const GetClientDetails = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post('api/getClientDetails', payload);
    dispatch({
      type: types.GET_CLIENT_DETAILS_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for logging in a user
export const SignIn = (payload) => async (dispatch) => {
  let data = {
    email: payload.email,
    password: payload.password,
  };
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post('api/verifyLogin', data);

    if (response.data.success === true) {
      ls.set('isAuthenticated', response.data.success, { encrypt: true });
      ls.set('client', response.data, { encrypt: true });
      window.location.replace('/complete-order');
    } else {
      ls.set('isAuthenticated', response.data.success, { encrypt: true });
    }

    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: [],
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for resetting user account password
export const ResetPwd = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post('api/reset', payload);
    dispatch({
      type: types.RESET_PWD_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for updating user account password
export const UpdatePwd = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post('api/update/password', payload);
    dispatch({
      type: types.UPDATE_PWD_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for checking use email
export const CheckEmail = (payload) => async (dispatch) => {
  const data = {
    email: payload.email,
  };
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post(`api/checkEmail`, data);
    dispatch({
      type: types.CHECK_EMAIL_SUCCESS,
      payload: response.data,
    });
    ls.set('UserPreInfo', payload, { encrypt: true });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for sending email verification
export const SendVerification = (payload) => async (dispatch) => {
  let data = {
    draft_id: payload.draft_id,
    email: payload.email,
    first_name: payload.firstname,
    last_name: payload.lastname,
    has_domain: payload.has_domain,
    country: payload.country,
  };
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post(
      `api/email/verification-notification`,
      data
    );
    dispatch({
      type: types.SEND_VERIFICATION_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for verifying a token
export const VerifyAccToken = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post(`api/email/verification-token`, payload);
    ls.set('order_draft', response.data.data.draft_id, { encrypt: true });
    ls.set('UserPreInfo', response.data.data, { encrypt: true });
    ls.set('has_domain', response.data.data.has_domain, { encrypt: true });
    ls.set('current_country', response.data.data.country, { encrypt: true });
    dispatch({
      type: types.VERIFY_TOKEN_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for signing out a user
export const Logout = (payload) => async (dispatch) => {
  try {
    const response = await axios.post('api/logout', payload, {
      headers: {
        Authorization: 'Bearer ' + payload,
      },
    });
    if (response.status === 200) {
      localStorage.clear();
      window.location.replace(baseUrl + '/app/auth');
    }
    dispatch({
      type: types.LOGOUT_SUCCESS,
    });
  } catch (error) {
    if (error.response.data.message === 'Unauthenticated.') {
      localStorage.clear();
      window.location.replace(baseUrl + '/app/auth');
    }
  }
};
