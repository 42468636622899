import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Home } from './pages/Home';
import { SearchDomain } from './pages/SearchDomain';
import { Order } from './pages/Order';
import { CompleteOrder } from './pages/CompleteOrder';
import { AllFeatures } from './pages/AllFeatures';
import { Verify } from './pages/Verify';
import { AccountSetup } from './components/AccountSetup';
import { BuyDomain } from './pages/BuyDomain';
import { TransferDomain } from './pages/TransferDomain';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/search-domain" element={<SearchDomain />} />
            <Route exact path="/buy-domain" element={<BuyDomain />} />
            <Route exact path="/transfer-domain" element={<TransferDomain />} />
            <Route exact path="/order" element={<Order />} />
            <Route exact path="/complete-order" element={<CompleteOrder />} />
            <Route exact path="/all-features" element={<AllFeatures />} />
            <Route exact path="/verify/:token" element={<Verify />} />
            <Route exact path="/setup" element={<AccountSetup />} />
            <Route exact path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
