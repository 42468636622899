import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { CheckDomain, SetDomainDetails } from '../redux/actions/domain';
import { GetProducts } from '../redux/actions/product';
import { AddOrderDraftItem, CreateOrderDraft } from '../redux/actions/order';

import { MsgText } from './MsgText';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { domain_ext } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loaderStyle: {
    width: '20px !important',
    height: '20px !important',
    color: '#fff !important',
    margin: '0px auto !important',
  },
  innerLoaderStyle: {
    width: '20px !important',
    height: '20px !important',
    color: '#4580F9 !important',
    margin: '0px auto !important',
  },
}));

export const BuyDomainSection = () => {
  let initialValues = {
    domain_name: '',
    owned_domain_name: '',
    domain_ext: '',
    epp_code: '',
  };
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState(null);
  const [searchingFor, setSearchingFor] = useState(null);
  const [currentTab, setCurrentTab] = useState('need_domain');
  const [request, setRequest] = useState('all');
  const [innerLoader, setInnerLoader] = useState(false);
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(GetProducts());
  }, []);
  const { domain, domain_period, domain_details } = useSelector(
    (state) => state.domain
  );
  const { order_message } = useSelector((state) => state.order);
  const { isLoading } = useSelector((state) => state.loader);

  useEffect(() => {
    ls.set('currentTab', 'need_domain', { encrypt: true });
    ls.set('has_domain', false, { encrypt: true });
    setRequest('domain');
    dispatch(CreateOrderDraft());
  }, []);

  useEffect(() => {
    if (domain) {
      setSearchResult({ ...domain, domain_name: searchingFor });
    }
  }, [domain, searchingFor]);

  const searchValidationSchema = Yup.object().shape({
    domain_name: Yup.string()
      .required()
      .trim()
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        'Only numbers & alphabets are allowed for this field'
      ),
    domain_ext: Yup.string().required().label('Domain extension'),
  });

  // All form validations

  // All form handlers
  const handleSearch = (values, onSubmitProps, ...props) => {
    let domain_name = `${values.domain_name}${values.domain_ext}`;
    SaveExtId(values.domain_ext);
    SaveHost(values.domain_name);
    SaveDomainDetails(values.domain_name, values.domain_ext);
    setSearchingFor(domain_name);
    ls.set('chosen_domain', domain_name, { encrypt: true });
    dispatch(CheckDomain(domain_name));
  };

  const SaveExtId = (ext) => {
    ls.set(
      'chosen_domain_extension',
      domain_ext.products.filter((product) => product.name === ext),
      { encrypt: true }
    );
    ls.set('chosen_ext', ext, { encrypt: true });
  };

  const SaveHost = (host) => {
    ls.set('chosen_host', host, { encrypt: true });
  };

  const SaveDomainDetails = (host, extension, domain_name) => {
    if (products.domains !== undefined && products.domains.length > 0) {
      products.domains
        .filter((domain) => domain.name === extension)
        .map((filteredDomain) => {
          dispatch(
            SetDomainDetails({
              ...filteredDomain,
              host: host,
              domain_name: domain_name,
            })
          );
        });
    }
  };

  const HandleAddOrderDraftItem = () => {
    setInnerLoader(true);
    let chosen_domain_details = ls.get('chosen_domain', { decrypt: true });
    let chosen_host = ls.get('chosen_host', { decrypt: true });

    if (
      chosen_domain_details !== undefined &&
      chosen_domain_details !== '' &&
      chosen_host !== undefined &&
      chosen_host !== ''
    ) {
      let data = {
        draft_id: ls.get('order_draft', { decrypt: true }),
        cycle: 'a',
        domain_name: ls.get('chosen_domain', { decrypt: true }),
        domain_period: domain_period !== undefined ? domain_period : 1,
        domain_action: 'register',
        requests: request,
      };

      dispatch(
        AddOrderDraftItem({
          ...data,
          domain_sld: ls.get('chosen_host', { decrypt: true }),
          domain_tld: parseInt(
            ls.get('chosen_domain_extension', { decrypt: true })
          )[0].id,
        })
      );
    }
  };

  useEffect(() => {
    if (order_message === 'added') {
      setInnerLoader(false);

      navigate('/order');
    }
  }, [order_message]);

  return (
    <>
      <section className="section">
        <div className="container mt-9">
          <div className="row">
            <div className="col-md-8 m-auto">
              <div className="card card-pricing bg-white text-center mb-4">
                <div className="card-header bg-transparent">
                  <h2 className="font-weight-bold text-uppercase ls-1 pt-3 ">
                    {' '}
                    Buy a Domain Name
                  </h2>
                  <div className="row">
                    <div className="col-md-6 m-auto">
                      {/* <!-- <p>Your domain will be your website's address. You can creat a new domain or use one you already own.</p> --> */}
                      <p className="my-0 f-8px pb-3">
                        All available domains and prices will be listed below,
                        Get a free domain when bought with a{' '}
                        <Link to="/">
                          <b>hosting plan</b>
                        </Link>
                        .
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    {currentTab === 'need_domain' && (
                      <div
                        className={`tab-pane fade ${
                          currentTab === 'need_domain' ? 'active show' : ''
                        }`}
                        id="need_domain"
                      >
                        <div className="row mt-4">
                          <div className="col-md-12 m-auto">
                            <Formik
                              initialValues={initialValues}
                              onSubmit={handleSearch}
                              enableReinitialize
                              validationSchema={searchValidationSchema}
                            >
                              {({
                                values,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                touched,
                                handleBlur,
                                errors,
                              }) => (
                                <form
                                  className="code-validate"
                                  action="POST"
                                  onSubmit={handleSubmit}
                                >
                                  <div className="input-group">
                                    <input
                                      style={{
                                        borderRadius:
                                          '50rem 0rem 0rem 50rem !important',
                                      }}
                                      type="text"
                                      className="form-control p-4"
                                      name="domain_name"
                                      placeholder="Find your domain"
                                      aria-label="domain"
                                      value={values.domain_name}
                                      onChange={handleChange('domain_name')}
                                      onBlur={handleBlur('domain_name')}
                                    />
                                    <div className="input-group-append">
                                      <select
                                        className="extension_selector"
                                        name="domain_ext"
                                        value={values.domain_ext}
                                        onChange={handleChange('domain_ext')}
                                        onBlur={handleBlur('domain_ext')}
                                      >
                                        <option defaultValue>.example</option>
                                        {domain_ext.products !== undefined &&
                                          domain_ext.products.length > 0 &&
                                          domain_ext.products.map((ext) => (
                                            <option
                                              key={ext.id}
                                              value={ext.name}
                                            >
                                              {ext.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <div className="input-group-append">
                                      <button
                                        style={{
                                          borderRadius:
                                            '0rem 50rem 50rem 0rem !important',
                                        }}
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        {isLoading ? (
                                          <CircularProgress
                                            className={classes.loaderStyle}
                                          />
                                        ) : (
                                          'Search'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  {touched.domain_name &&
                                    errors.domain_name && (
                                      <MsgText
                                        text={errors.domain_name}
                                        textColor="danger"
                                      />
                                    )}
                                  {touched.domain_ext && errors.domain_ext && (
                                    <MsgText
                                      text={errors.domain_ext}
                                      textColor="danger"
                                    />
                                  )}
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12 m-auto">
                            {searchResult !== null &&
                              searchResult.hasOwnProperty('domain_available') &&
                              searchResult.domain_available === 'yes' &&
                              isLoading === false && (
                                <div className="card mb-4 shadow-sm border-bottom">
                                  <div className="card-body px-4 py-2">
                                    <div className="row">
                                      <div className="col-md-8 text-left">
                                        <p className="p-0 m-0">
                                          <strong>
                                            {searchResult.domain_name}
                                          </strong>{' '}
                                          is available for{' '}
                                          <span className="text-success">
                                            {domain_details.register} RWF per
                                            Year
                                          </span>
                                        </p>
                                      </div>
                                      <div className="col-md-4 text-right">
                                        {innerLoader === false ? (
                                          <button
                                            onClick={() => {
                                              ls.set(
                                                'chosen_domain',

                                                searchResult.domain_name,
                                                { encrypt: true }
                                              );
                                              HandleAddOrderDraftItem();
                                            }}
                                            className="btn btn-outline-primary btn-round btn-sm pl-3 pr-3"
                                          >
                                            Buy
                                          </button>
                                        ) : (
                                          <CircularProgress
                                            className={classes.innerLoaderStyle}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {searchResult !== null &&
                              searchResult.hasOwnProperty('domain_available') &&
                              searchResult.domain_available === 'no' && (
                                <div className="card mb-2 shadow-sm border-bottom">
                                  <div className="card-body px-4 py-2">
                                    <div className="row">
                                      <div className="col-md-8 text-left">
                                        <p className="p-0 m-0">
                                          <strong>
                                            {searchResult.domain_name}
                                          </strong>{' '}
                                          is not available
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
