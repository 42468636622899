import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GetProducts } from '../redux/actions/product';
import { CreateOrderDraft } from '../redux/actions/order';
import { clearErrors } from '../redux/actions/errors';
import { services } from '../constants';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Card, PopoverBody, PopoverHeader } from 'reactstrap';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
export const Plans = () => {
  const dispatch = useDispatch();
  const { products, message } = useSelector((state) => state.product);
  const { default_currency } = useSelector((state) => state.currency);
  useEffect(() => {
    if (default_currency !== null && default_currency.hasOwnProperty('code')) {
      dispatch(GetProducts());
    }
  }, [default_currency]);

  const handleCreateOrderDraft = () => {
    dispatch(CreateOrderDraft());
  };

  const [currentServiceName, setCurrentServiceName] = useState(null);
  const [currentServiceDesc, setCurrentServiceDesc] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <section className="plans_section bg-primary pb-5">
        <div className="container mt-md--8 mt--5 ">
          <div className="row">
            {products.product !== undefined && products.product.length > 0 ? (
              products.product.map((product) => (
                <div className="col-md-3 col-sm-6" key={product.id}>
                  <div className="card card-pricing bg-white text-center mb-4">
                    <div className="card-header bg-transparent">
                      <h5 className="font-weight-bold text-uppercase ls-1 py-3 mb-0">
                        {product.name}
                      </h5>
                    </div>
                    <div className="card-body">
                      <h6 className="text-muted text-center">
                        <span className="badge badge-pill badge-md badge-success mr-1 text-uppercase">
                          Save 50%
                        </span>
                        <span>First Year</span>
                      </h6>
                      <div className="display-2 d-flex justify-content-center ">
                        {' '}
                        <span className="font-weight-bold ">
                          {new Intl.NumberFormat('en-US').format(
                            product.price *
                              (default_currency.code !== 'FCFA'
                                ? parseFloat(default_currency.rate)
                                : 1)
                          )}
                        </span>{' '}
                        <span
                          className="font-weight-400 pt-3 text-left"
                          style={{ fontSize: '13px' }}
                        >
                          {default_currency.code} <br />
                          Monthly
                        </span>
                      </div>
                      <ul className="list-unstyled my-2">
                        <li className="align-items-center">
                          Renews at{' '}
                          <strong>
                            {new Intl.NumberFormat('en-US').format(
                              product.price *
                                2 *
                                (default_currency.code !== 'FCFA'
                                  ? parseFloat(default_currency.rate)
                                  : 1)
                            )}
                            <sup style={{ fontSize: '16px', lineHeight: '1' }}>
                              {default_currency.code}
                            </sup>
                          </strong>{' '}
                          monthly
                        </li>
                        <Link
                          onClick={() => {
                            handleCreateOrderDraft();
                            ls.set('chosen_product_id', product.hostbill_id, {
                              encrypt: true,
                            });
                            ls.set(
                              'chosen_product',

                              products.product.filter(
                                (prod) =>
                                  prod.hostbill_id === product.hostbill_id
                              ),
                              { encrypt: true }
                            );
                          }}
                          to="/search-domain"
                          className="btn btn-primary btn-round btn-lg w-100"
                        >
                          Order Now
                        </Link>
                      </ul>
                    </div>
                    <div className="card-footer text-center bg-transparent">
                      <ul className="list-group simple-list mb-3 d-none d-md-flex">
                        {services
                          .filter(
                            (service) => service.product_id === product.id
                          )
                          .map((filteredService) => (
                            <span
                              key={`${filteredService.id}-${filteredService.product_id}`}
                              className={
                                filteredService.important === true
                                  ? `mb-2 tooltipped text-success`
                                  : `mb-2 tooltipped`
                              }
                              id={`popover-${filteredService.id}-${filteredService.product_id}`}
                            >
                              <i
                                className={
                                  filteredService.important === true
                                    ? `fas fa-star`
                                    : `fa fa-check text-success`
                                }
                              ></i>{' '}
                              {filteredService.name}{' '}
                              <i
                                className="fas text-light fa-info-circle"
                                aria-owns={
                                  open ? 'mouse-over-popover' : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={(event) => {
                                  handlePopoverOpen(event);
                                  setCurrentServiceName(filteredService.name);
                                  setCurrentServiceDesc(
                                    filteredService.description
                                  );
                                }}
                                onMouseLeave={(event) => {
                                  handlePopoverClose(event);
                                  setCurrentServiceName(null);
                                  setCurrentServiceDesc(null);
                                }}
                              ></i>
                            </span>
                          ))}
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: 'none',
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <Typography
                            style={{
                              width: '260px',
                              color: '#fff',
                              background:
                                'linear-gradient(35deg, #3080f8 0, #825ee4 100%)',
                            }}
                            sx={{ p: 2 }}
                          >
                            <div className="mb-2">
                              <b className="text-center">
                                {' '}
                                {currentServiceName}
                              </b>
                            </div>

                            <span
                              style={{ fontSize: '14px', lineHeight: '2px' }}
                            >
                              {currentServiceDesc}
                            </span>
                          </Typography>
                        </Popover>
                      </ul>

                      {/* For better Accessibility on mobile */}
                      <ul className="list-group simple-list mb-3 d-md-none">
                        {services
                          .filter(
                            (service) => service.product_id === product.id
                          )
                          .map((filteredService) => (
                            <span
                              key={`${filteredService.id}-${filteredService.product_id}`}
                              className={
                                filteredService.important === true
                                  ? `mb-2 tooltipped text-success`
                                  : `mb-2 tooltipped`
                              }
                              id={`popover-${filteredService.id}-${filteredService.product_id}`}
                            >
                              <i
                                className={
                                  filteredService.important === true
                                    ? `fas fa-star`
                                    : `fa fa-check text-success`
                                }
                              ></i>{' '}
                              {filteredService.name}{' '}
                              <i
                                className="fas text-light fa-info-circle"
                                aria-owns={
                                  open ? 'mouse-over-popover' : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={(event) => {
                                  handlePopoverOpen(event);
                                  setCurrentServiceName(filteredService.name);
                                  setCurrentServiceDesc(
                                    filteredService.description
                                  );
                                }}
                                onMouseLeave={(event) => {
                                  handlePopoverClose(event);
                                  setCurrentServiceName(null);
                                  setCurrentServiceDesc(null);
                                }}
                              ></i>
                            </span>
                          ))}
                      </ul>
                      {/* For better Accessibility on mobile */}

                      <hr className="my-1" />
                      <Link
                        to="/"
                        type="button"
                        className="btn btn-link text-primary"
                      >
                        See all Features
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="col-md-3 col-sm-6">
                  <div className="card card-pricing bg-white text-center mb-4">
                    <div className="card-body">
                      <Stack spacing={1}>
                        <Skeleton
                          style={{ borderRadius: '50%;' }}
                          variant="text"
                          height={50}
                        />
                        <Skeleton variant="rectangular" height={168} />
                        <Skeleton variant="text" height={70} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={40} />
                      </Stack>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="card card-pricing bg-white text-center mb-4">
                    <div className="card-body">
                      <Stack spacing={1}>
                        <Skeleton variant="text" height={50} />
                        <Skeleton variant="rectangular" height={168} />
                        <Skeleton variant="text" height={70} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={40} />
                      </Stack>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="card card-pricing bg-white text-center mb-4">
                    <div className="card-body">
                      <Stack spacing={1}>
                        <Skeleton variant="text" height={50} />
                        <Skeleton variant="rectangular" height={168} />
                        <Skeleton variant="text" height={70} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={40} />
                      </Stack>
                    </div>
                  </div>
                </div>
              </>
            )}

            {products.product !== undefined && products.product.length > 0 ? (
              <div className="col-md-3 col-sm-6">
                <div className="card card-pricing bg-default border-0 text-center mb-4">
                  <div className="card-header bg-transparent">
                    <h5 className="font-weight-bold text-uppercase text-white ls-1 py-3 mb-0">
                      VPS Plans
                    </h5>
                  </div>
                  <div className="card-body text-white">
                    <h6 className="text-muted text-white text-center">
                      <span className="badge badge-pill badge-md badge-success mr-1">
                        Coming soon
                      </span>
                      <br />
                      {/* <span className="badge badge-pill badge-success mr-1">SAVE 10%</span> */}
                      <span>Starting at</span>
                    </h6>
                    <div className="display-2 d-flex justify-content-center ">
                      {' '}
                      <span className="font-weight-bold ">
                        {' '}
                        {new Intl.NumberFormat('en-US').format(
                          20000 *
                            (default_currency.code !== 'FCFA'
                              ? parseFloat(default_currency.rate)
                              : 1)
                        )}
                      </span>
                      <span
                        className="font-weight-400 pt-3 text-left"
                        style={{ fontSize: '16px', lineHeight: '1' }}
                      >
                        {default_currency.code} <br />
                        Monthly
                      </span>
                    </div>
                    <ul className="list-unstyled my-2 text-white">
                      {/* <li className="align-items-center text-white">
                          Renews at 30,000 RWF/months
                        </li> */}
                      <button className="btn btn-white btn-round btn-lg w-100 disabled">
                        Coming soon
                      </button>
                    </ul>
                  </div>
                  <div className="card-footer text-center bg-transparent">
                    <ul className="list-group simple-list mb-3 d-none d-md-flex">
                      <span
                        className="mb-2 text-success tooltipped"
                        title="Dedicated Support"
                        data-toggle="popover"
                        data-content="<p className='my-0 f-8px'></p>"
                      >
                        <i className="fas fa-star "></i> Dedicated Support{' '}
                        <i
                          className="fas fa-info-circle text-light"
                          aria-owns={open ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={(event) => {
                            handlePopoverOpen(event);
                            setCurrentServiceName(`Dedicated Support`);
                            setCurrentServiceDesc(
                              `A Dedicated Support expert will always monitor your hosting and help you manage your hosting needs and goals so you can focus on your business.`
                            );
                          }}
                          onMouseLeave={(event) => {
                            handlePopoverClose(event);
                            setCurrentServiceName(null);
                            setCurrentServiceDesc(null);
                          }}
                        ></i>
                      </span>

                      <span
                        className="mb-2 text-success tooltipped"
                        title="Dedicated IP address"
                        data-toggle="popover"
                      >
                        <i className="fas fa-star "></i> Dedicated IP address{' '}
                        <i
                          className="fas fa-info-circle text-light"
                          aria-owns={open ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={(event) => {
                            handlePopoverOpen(event);
                            setCurrentServiceName(`Dedicated IP address`);
                            setCurrentServiceDesc(
                              `Benefit from Higher On-Demand Website Access, Improve Email Deliverability, High Level of Security, High Level of Uptime with a Dedicated IP address on this package.`
                            );
                          }}
                          onMouseLeave={(event) => {
                            handlePopoverClose(event);
                            setCurrentServiceName(null);
                            setCurrentServiceDesc(null);
                          }}
                        ></i>
                      </span>
                    </ul>

                    {/* For better Accessibility on mobile */}
                    <ul className="list-group simple-list mb-3 d-md-none">
                      <span
                        className="mb-2 text-success tooltipped"
                        title="Dedicated Support"
                        data-toggle="popover"
                      >
                        <i className="fas fa-star "></i> Dedicated Support{' '}
                        <i
                          className="fas text-light fa-info-circle"
                          aria-owns={open ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={(event) => {
                            handlePopoverOpen(event);
                            setCurrentServiceName(`Dedicated Support`);
                            setCurrentServiceDesc(
                              `A Dedicated Support expert will always monitor your hosting and help you manage your hosting needs and goals so you can focus on your business.`
                            );
                          }}
                          onMouseLeave={(event) => {
                            handlePopoverClose(event);
                            setCurrentServiceName(null);
                            setCurrentServiceDesc(null);
                          }}
                        ></i>
                      </span>

                      <span
                        className="mb-2 text-success tooltipped"
                        title="Dedicated IP address"
                        data-toggle="popover"
                      >
                        <i className="fas fa-star "></i> Dedicated IP address{' '}
                        <i
                          className="fas fa-info-circle text-light"
                          aria-owns={open ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={(event) => {
                            handlePopoverOpen(event);
                            setCurrentServiceName(`Dedicated IP address`);
                            setCurrentServiceDesc(
                              `Benefit from Higher On-Demand Website Access, Improve Email Deliverability, High Level of Security, High Level of Uptime with a Dedicated IP address on this package.`
                            );
                          }}
                          onMouseLeave={(event) => {
                            handlePopoverClose(event);
                            setCurrentServiceName(null);
                            setCurrentServiceDesc(null);
                          }}
                        ></i>
                      </span>
                    </ul>
                    {/* For better Accessibility on mobile */}
                    <hr className="my-1" />
                    <Link
                      to=""
                      type="button"
                      className="btn btn-link text-white"
                    >
                      See all Features
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="col-md-3 col-sm-6">
                  <div className="card card-pricing bg-white text-center mb-4">
                    <div className="card-body">
                      <Stack spacing={1}>
                        <Skeleton variant="text" height={50} />
                        <Skeleton variant="rectangular" height={168} />
                        <Skeleton variant="text" height={70} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={40} />
                      </Stack>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
