import React, { useEffect, useRef, useState } from 'react';
import ls from 'localstorage-slim';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {
  SignIn,
  clearMessage,
  CheckEmail,
  SendVerification,
} from '../redux/actions/auth';
import {
  AddOrderDraftItem,
  GetOrderDraft,
  RemoveOrderDraftItem,
} from '../redux/actions/order';
import { GetProducts } from '../redux/actions/product';
import { clearErrors } from '../redux/actions/errors';
import { MsgText } from './MsgText';
import clsx from 'clsx';
import { Alert, CircularProgress, IconButton, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsPencil, BsXLg } from 'react-icons/bs';
import { domain_ext } from '../constants';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CheckDomain } from '../redux/actions/domain';
import { ContactsOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loaderStyle: {
    width: '20px !important',
    height: '20px !important',
    color: '#fff !important',
    margin: '0px auto !important',
  },
  _loaderStyle: {
    width: '50px !important',
    height: '50px !important',
    color: '#4580F9 !important',
    margin: '170px auto !important',
    zIndex: 1,
    position: 'absolute !important',
  },

  cardMask: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: '#fff',
    filter: 'blur(8px)',
    //opacity: 0.4,
  },
  center: {
    margin: 'auto',
    width: '10%',
  },
}));

export const OrderSection = () => {
  let initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    terms: '',
  };
  const classes = useStyles();
  const navigate = useNavigate();
  const { default_currency } = useSelector((state) => state.currency);
  const { products } = useSelector((state) => state.product);
  const { isLoading, _isLoading } = useSelector((state) => state.loader);
  const { domain, domain_period, domain_message, domain_details } = useSelector(
    (state) => state.domain
  );
  const { order, order_message } = useSelector((state) => state.order);
  const { email_verification, isAuthenticated, message } = useSelector(
    (state) => state.auth
  );
  const { error_msg } = useSelector((state) => state.errors);
  const [currentTab, setCurrentTab] = useState('create');
  const [currentMTab, setCurrentMTab] = useState('need_domain');
  const [currentFormData, setCurrentFormData] = useState(null);
  const [currentProd, setCurrentProd] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState(1);
  const [currentDomain, setCurrentDomain] = useState(null);
  const [emailExist, setEmailExist] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [showPackagesField, setShowPackagesField] = useState(false);
  const [showPeriodField, setShowPeriodField] = useState(false);
  const [renewalPrice, setRenewalPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalMonthlyPrice, setTotalMonthlyPrice] = useState(0);
  const [serviceProductId, setServiceProductId] = useState();
  const [domainProductId, setDomainProductId] = useState();
  const [changeBtn, setChangeBtn] = useState();
  const [isValid, setIsValid] = useState(false);
  const [request, setRequest] = useState('all');
  const [innerLoader, setInnerLoader] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [searchingFor, setSearchingFor] = useState(null);
  // Modal open state
  const [modal, setModal] = useState(false);
  const [hasDomain, setHasDomain] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [domainDetails, setDomainDetails] = useState(false);
  const [isBuyingDomain, setIsBuyingDomain] = useState(false);
  const [dProduct, setDProduct] = useState(null);

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();

  useEffect(() => {
    let single_product = ls.get('chosen_product', { decrypt: true });
    let chosen_domain = ls.get('chosen_domain', { decrypt: true });
    let draft_id = ls.get('order_draft', { decrypt: true });
    let has_domain = ls.get('has_domain', { decrypt: true });
    let domain_details = ls.get('domain_details', { decrypt: true });
    if (draft_id && draft_id !== null && draft_id !== undefined) {
      dispatch(GetOrderDraft(draft_id));
    }
    setDProduct(domain_details);
    setCurrentProd(single_product);
    setCurrentDomain(chosen_domain);
    setHasDomain(has_domain);
    dispatch(GetProducts());
    setSearchResult(null);
  }, []);
  useEffect(() => {
    if (_isLoading === false) {
      let chosen_domain = ls.get('chosen_domain', { decrypt: true });
      setCurrentDomain(chosen_domain);
    }
  }, [_isLoading]);
  useEffect(() => {
    if (domain) {
      setSearchResult({ ...domain, domain_name: searchingFor });
    }
  }, [domain, searchingFor]);
  // All Validations
  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().required().email().label('Email'),
    password: Yup.string()
      .required()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
      .label('Password'),
  });

  const checkEmailValidationSchema = Yup.object().shape({
    firstname: Yup.string().required().label('First name'),
    lastname: Yup.string().required().label('Last name'),
    email: Yup.string().required().label('Email'),
    terms: Yup.mixed().required().label('Term and Conditions'),
  });

  // All form handles
  const handleLogin = (values, onSubmitProps) => {
    dispatch(clearErrors());
    dispatch(clearMessage());
    dispatch(SignIn({ ...values }));
    setCurrentFormData(onSubmitProps);
  };

  const handleCheckEmail = (values, onSubmitProps) => {
    dispatch(clearErrors());
    dispatch(clearMessage());
    dispatch(CheckEmail({ ...values }));
    setCurrentFormData(onSubmitProps);
  };
  useEffect(() => {
    if (
      email_verification !== undefined &&
      email_verification.hasOwnProperty('isExist') &&
      email_verification.isExist === false
    ) {
      let preInfo = ls.get('UserPreInfo', { decrypt: true });
      let draft_id = ls.get('order_draft', { decrypt: true });
      let current_country = ls.get('current_country', { decrypt: true });
      // Send verification email

      dispatch(
        SendVerification({
          ...preInfo,
          draft_id,
          has_domain: hasDomain,
          country:
            default_currency !== undefined ? default_currency.country_code : '',
        })
      );
      //navigate('/complete-order');
    } else {
      setEmailExist(email_verification.isExist);
    }
  }, [email_verification]);

  useEffect(() => {
    if (message === 'verification sent') {
      setEmailSent(true);
    }
  }, [message]);

  useEffect(() => {
    let preInfo = ls.get('UserPreInfo', { decrypt: true });
    if (preInfo !== undefined && preInfo !== null) {
      if (preInfo.hasOwnProperty('email')) {
        setUserEmail(preInfo.email);
      }
    }
  }, []);

  const handleChangePackage = (product_id) => {
    let data = products.product.filter(
      (prod) => prod.hostbill_id === product_id
    );
    ls.set(
      'chosen_product',
      products.product.filter((prod) => prod.hostbill_id === product_id),
      { encrypt: true }
    );
    setCurrentProd(
      products.product.filter((prod) => prod.hostbill_id === product_id)
    );
    setShowPackagesField(false);
  };

  const handleChangePeriod = (period) => {
    ls.set('chosen_period', period, { encrypt: true });
    setCurrentPeriod(period);
    setShowPeriodField(false);
  };

  useEffect(() => {
    if (currentProd) {
      let renew_price = currentProd.renew_price * 12;
      setRenewalPrice(renew_price);
    }
  }, [currentProd]);

  // useEffect(() => {
  //   if (order) {
  //     if (order.success === false) {
  //       navigate('/');
  //     }
  //   }
  // }, [order, navigate]);

  // useEffect(() => {
  //   let isAuth = ls.get('isAuthenticated', { decrypt: true });
  //   if (isAuthenticated === true || isAuth === true) {
  //     navigate('/complete-order');
  //   }
  // }, [isAuthenticated]);

  const calculator = (renewal_price, period, type) => {
    switch (type) {
      case 'monthly_price':
        let m_price =
          (12 * renewal_price + 24 * renewal_price * (period - 1)) /
          (24 * period);
        setTotalMonthlyPrice(m_price);
        break;
      case 'total_price':
        let t_price =
          (12 * renewal_price + 24 * renewal_price * (period - 1)) / 2;
        setTotalPrice(t_price);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    calculator(renewalPrice, currentPeriod);
  }, [currentPeriod, renewalPrice]);

  useEffect(() => {
    if (order.hasOwnProperty('success')) {
      if (order.success === false) {
        navigate('/');
        return;
      }
      if (order.draft !== undefined) {
        let obj_key = Object.keys(order.draft.services);
        let _obj_key = Object.keys(order.draft.domains);
        ls.set(
          'chosen_product_id',
          order.draft.services.hasOwnProperty('1')
            ? order.draft.services[obj_key[0]].product_id
            : '',
          { encrypt: true }
        );
        setServiceProductId(
          order.draft.services.hasOwnProperty('1')
            ? order.draft.services[obj_key[0]].product_id
            : ''
        );

        setCurrentPeriod(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].period
            : 1
        );
        setCurrentDomain(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].name
            : order.draft.services[obj_key[0]].domain
        );
        setDomainDetails(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]]
            : ''
        );
      }
    }
  }, [order]);

  useEffect(() => {
    if (order_message === 'removed') {
      let data = {
        draft_id: ls.get('order_draft', { decrypt: true }),
        product_id:
          isBuyingDomain === false &&
          ls.get('chosen_product', { decrypt: true })[0].hostbill_id,
        cycle: 'a',
        domain_name: ls.get('chosen_domain', { decrypt: true }),
        domain_period: currentPeriod !== undefined ? currentPeriod : 1,
        domain_action: 'register',
        requests: request,
      };
      if (hasDomain === false) {
        dispatch(
          AddOrderDraftItem({
            ...data,
            domain_sld: ls.get('chosen_host', { decrypt: true }),
            domain_tld: parseInt(
              ls.get('chosen_domain_extension', { decrypt: true })
            )[0].id,
          })
        );
      } else {
        dispatch(AddOrderDraftItem(data));
      }
    }
    if (order_message === 'added') {
      let draft_id = ls.get('order_draft', { decrypt: true });
      if (draft_id && draft_id !== null && draft_id !== undefined) {
        dispatch(GetOrderDraft(draft_id));
      }
    }
  }, [order_message]);
  const handleRemoveDraftItem = (action) => {
    if (action === 'domain' && hasDomain === true) {
      return;
    }
    setRequest(action);
    let draft_id = ls.get('order_draft', { decrypt: true });
    let data = {
      draft_id,
    };
    switch (action) {
      case 'all':
        data = { ...data, service_id: 1, domain_id: 1 };
        break;
      case 'service':
        data = { ...data, service_id: 1 };
        break;
      case 'domain':
        data = { ...data, domain_id: 1 };
        break;
      default:
        return 'Invalid request type';
    }

    if (draft_id && draft_id !== null && draft_id !== undefined) {
      dispatch(RemoveOrderDraftItem(data));
    }
  };

  // All form validations
  const searchValidationSchema = Yup.object().shape({
    domain_name: Yup.string()
      .required()
      .trim()
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        'Only numbers & alphabets are allowed for this field'
      ),
    domain_ext: Yup.string().required().label('Domain extension'),
  });

  const ownsDomainValidationSchema = Yup.object().shape({
    owned_domain_name: Yup.string().required().label('Domain name'),
    dns1: Yup.string().required().label('DNS 1'),
    dns2: Yup.string().required().label('DNS 2'),
  });

  // All form handles
  const handleSearch = (values, onSubmitProps, ...props) => {
    let domain_name = `${values.domain_name}${values.domain_ext}`;
    SaveExtId(values.domain_ext);
    SaveHost(values.domain_name);
    setSearchingFor(domain_name);
    dispatch(CheckDomain(domain_name));
  };
  const handleOwnsDomain = (values, onSubmitProps) => {
    //console.log({ values });
  };

  const validateDomain = (domain_name) => {
    SaveHost(domain_name.split('.')[0]);
    dispatch(CheckDomain(domain_name));
    setIsValid(true);
  };

  const SaveExtId = (ext) => {
    ls.set(
      'chosen_domain_extension',
      domain_ext.products.filter((product) => product.name === ext),
      { encrypt: true }
    );
    ls.set('chosen_ext', ext, { encrypt: true });
  };

  const SaveHost = (host) => {
    ls.set('chosen_host', host, { encrypt: true });
  };
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (open) {
      setOpen(!open);
    }
  }, [message, open]);
  return (
    <>
      {message === 'failed' && (
        <Snackbar
          open={!open}
          autoHideDuration={4000}
          onClose={() => setOpen(!open)}
        >
          <Alert
            onClose={() => setOpen(!open)}
            variant="filled"
            severity="error"
            sx={{ width: '100%' }}
          >
            Invalid credentials!
          </Alert>
        </Snackbar>
      )}
      <section className="section">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <h1 className="display-1 mb-4">You’re almost there!</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              {emailSent === true ? (
                <div className="card">
                  <div className="card-body">
                    <div className="row mt-5 mb-5">
                      <div className="col-md-12 text-center">
                        <img src="./assets/images/email-sent.png" alt="" />
                        <h3 className="font-weight-bold">Check your email</h3>
                        <p className="col-md-8 m-auto">
                          Your order has been saved, check your inbox and click
                          on the link to verify your email{' '}
                          <strong>{userEmail}</strong> and complete your order.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card card-pricing bg-white mb-4">
                  <div className="card-header bg-transparent">
                    <h2 className="font-weight-normal ls-1 pt-3 ">
                      {' '}
                      Login or Create an Account
                    </h2>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 m-auto">
                        <ul
                          className="nav nav-pills nav-fill flex-column flex-md-row"
                          id="tabs-icons-text"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <Link
                              onClick={() => {
                                setCurrentTab('create');
                              }}
                              className={`nav-link border rounded-pill ${
                                currentTab === 'create' ? 'active' : ''
                              }`}
                              data-toggle="tab"
                              to=""
                              role="tab"
                              aria-controls="plans"
                              aria-selected="true"
                            >
                              <p className="m-0 font-weight-bold">
                                Create Account
                              </p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={() => {
                                setCurrentTab('login');
                                ls.set('just_loggedIn', true, {
                                  encrypt: true,
                                });
                              }}
                              className={`nav-link border rounded-pill  ${
                                currentTab === 'login' ? 'active' : ''
                              }`}
                              data-toggle="tab"
                              to=""
                              role="tab"
                              aria-controls="domains"
                              aria-selected="false"
                            >
                              <p className="m-0 font-weight-bold">Login </p>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-content">
                      {currentTab === 'create' && (
                        <div
                          className={`tab-pane fade ${
                            currentTab === 'create' ? 'active show' : ''
                          }`}
                          id="create"
                        >
                          <div className="row">
                            <div className="col-md-12 m-auto">
                              <Formik
                                initialValues={initialValues}
                                onSubmit={handleCheckEmail}
                                enableReinitialize
                                validationSchema={checkEmailValidationSchema}
                              >
                                {({
                                  values,
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue,
                                  touched,
                                  handleBlur,
                                  errors,
                                }) => (
                                  <form
                                    className="code-validate"
                                    action="POST"
                                    onSubmit={handleSubmit}
                                  >
                                    {emailExist === false && (
                                      <>
                                        <p className="lead">
                                          We will send an email to{' '}
                                          <strong>{userEmail}</strong> verify
                                          this email address.
                                        </p>
                                        <p className="lead">
                                          Your order has been saved, check your
                                          inbox and click on the link to verify
                                          your email{' '}
                                          <strong>{userEmail}</strong> and
                                          complete your order.
                                        </p>
                                      </>
                                    )}
                                    {emailExist === true && (
                                      <p className="lead text-success">
                                        Looks like you already have an account
                                        with us,{' '}
                                        <Link
                                          onClick={() => {
                                            setCurrentTab('login');
                                          }}
                                          className="font-weight-bold"
                                          data-toggle="tab"
                                          to=""
                                          role="tab"
                                          aria-controls="domains"
                                          aria-selected="false"
                                        >
                                          Login instead
                                        </Link>
                                      </p>
                                    )}
                                    <div className="row">
                                      <div className="col-md-6 mt-3">
                                        <div className="form-group">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                <i className="ni ni-single-02"></i>
                                              </span>
                                            </div>
                                            <input
                                              className="form-control form-control-lg"
                                              placeholder="Enter your first name"
                                              type="text"
                                              value={values.firstname}
                                              onChange={handleChange(
                                                'firstname'
                                              )}
                                              onBlur={handleBlur('firstname')}
                                            />
                                          </div>
                                          {touched.firstname &&
                                            errors.firstname && (
                                              <MsgText
                                                text={errors.firstname}
                                                textColor="danger"
                                              />
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-md-6 mt-3">
                                        <div className="form-group">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                <i className="ni ni-single-02"></i>
                                              </span>
                                            </div>
                                            <input
                                              className="form-control form-control-lg"
                                              placeholder="Enter your last name"
                                              type="text"
                                              value={values.lastname}
                                              onChange={handleChange(
                                                'lastname'
                                              )}
                                              onBlur={handleBlur('lastname')}
                                            />
                                          </div>
                                          {touched.lastname &&
                                            errors.lastname && (
                                              <MsgText
                                                text={errors.lastname}
                                                textColor="danger"
                                              />
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                <i className="ni ni-email-83"></i>
                                              </span>
                                            </div>
                                            <input
                                              className="form-control form-control-lg"
                                              placeholder="Enter your email"
                                              type="text"
                                              value={values.email}
                                              onChange={handleChange('email')}
                                              onBlur={handleBlur('email')}
                                            />
                                          </div>
                                          {touched.email && errors.email && (
                                            <MsgText
                                              text={errors.email}
                                              textColor="danger"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row mt-2">
                                      <div className="col-md-9">
                                        <div className="custom-control custom-checkbox mt-3">
                                          <input
                                            className="custom-control-input"
                                            id="customCheck1"
                                            type="checkbox"
                                            onChange={handleChange('terms')}
                                            onBlur={handleBlur('terms')}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="customCheck1"
                                          >
                                            <span>
                                              By creating an account you agree
                                              to our{' '}
                                              <Link to="">
                                                Terms & Conditions
                                              </Link>{' '}
                                              and acknowledge receipt of the{' '}
                                              <Link to="">Privacy Policy</Link>{' '}
                                            </span>
                                          </label>
                                          {touched.terms && errors.terms && (
                                            <MsgText
                                              text={errors.terms}
                                              textColor="danger"
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <br />
                                      <div className="col-md-3 pt-4 text-center m-auto">
                                        <button
                                          type="submit"
                                          className="btn btn-lg btn-primary btn-round pl-5 pr-5"
                                        >
                                          {isLoading ? (
                                            <CircularProgress
                                              className={classes.loaderStyle}
                                            />
                                          ) : (
                                            'Continue'
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </div>
                      )}
                      {currentTab === 'login' && (
                        <div
                          className={`tab-pane fade ${
                            currentTab === 'login' ? 'active show' : ''
                          }`}
                          id="login"
                        >
                          <div className="row mt-4">
                            <div className="col-md-12 m-auto">
                              <Formik
                                initialValues={initialValues}
                                onSubmit={handleLogin}
                                enableReinitialize
                                validationSchema={loginValidationSchema}
                              >
                                {({
                                  values,
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue,
                                  touched,
                                  handleBlur,
                                  errors,
                                }) => (
                                  <form
                                    className="code-validate"
                                    action="POST"
                                    onSubmit={handleSubmit}
                                  >
                                    <div className="form-group mb-3">
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            <i className="ni ni-email-83"></i>
                                          </span>
                                        </div>
                                        <input
                                          className="form-control form-control-lg"
                                          placeholder="Email"
                                          type="email"
                                          value={values.email}
                                          onChange={handleChange('email')}
                                          onBlur={handleBlur('email')}
                                        />
                                      </div>
                                      {touched.email && errors.email && (
                                        <MsgText
                                          text={errors.email}
                                          textColor="danger"
                                        />
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            <i className="ni ni-lock-circle-open"></i>
                                          </span>
                                        </div>
                                        <input
                                          className="form-control form-control-lg"
                                          placeholder="Password"
                                          type="password"
                                          value={values.password}
                                          onChange={handleChange('password')}
                                          onBlur={handleBlur('password')}
                                        />
                                      </div>
                                      {touched.password && errors.password && (
                                        <MsgText
                                          text={errors.password}
                                          textColor="danger"
                                        />
                                      )}
                                    </div>
                                    <br />
                                    <div className="row">
                                      <div className="col-md-4 ml-auto text-right">
                                        <button
                                          type="submit"
                                          className="btn btn-lg btn-primary btn-round pl-5 pr-5"
                                        >
                                          {isLoading ? (
                                            <CircularProgress
                                              className={classes.loaderStyle}
                                            />
                                          ) : (
                                            'Login'
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-5">
              <div className={classes.orderSummary}>
                <div className={classes.center}>
                  {_isLoading === true && (
                    <CircularProgress className={classes._loaderStyle} />
                  )}
                </div>
                <div className={_isLoading === true ? classes.cardMask : ''}>
                  {dProduct === null && (
                    <div className="card">
                      {products.product !== undefined &&
                        products.product.length > 0 &&
                        default_currency !== undefined &&
                        products.product
                          .filter(
                            (product) =>
                              product.hostbill_id === serviceProductId
                          )
                          .map((filteredProduct) => (
                            <div
                              className="card-body pb-4 pt-4"
                              key={filteredProduct.id}
                            >
                              <h6 className="text-left text-uppercase font-weight-bold mb-3">
                                Order Summary
                              </h6>
                              <hr className="w-100 mb-4 m-0" />

                              <div className="item">
                                <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                  <div>
                                    <span>
                                      {filteredProduct !== null
                                        ? filteredProduct.name
                                        : 'NAN'}
                                    </span>
                                    <span className="ml-3">
                                      <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          setShowPackagesField(
                                            !showPackagesField
                                          );
                                          setChangeBtn('package');
                                        }}
                                        className="badge badge-primary rounded-pill"
                                      >
                                        {showPackagesField === false ? (
                                          'Change'
                                        ) : (
                                          <BsXLg />
                                        )}
                                      </span>
                                    </span>
                                  </div>

                                  <span className="font-weight-bold text-uppercase">
                                    {new Intl.NumberFormat('en-US').format(
                                      filteredProduct !== null &&
                                        default_currency !== undefined
                                        ? ((12 * filteredProduct.renewal_price +
                                            24 *
                                              filteredProduct.renewal_price *
                                              (parseInt(currentPeriod) - 1)) /
                                            2) *
                                            (default_currency.code !== 'FCFA'
                                              ? parseFloat(
                                                  default_currency.rate
                                                )
                                              : 1)
                                        : 0
                                    )}{' '}
                                    {default_currency !== undefined &&
                                      default_currency.code}
                                  </span>
                                </h6>
                                {showPackagesField === true && (
                                  <div>
                                    <select
                                      className="form-control form-control-sm col-md-5 custom-select"
                                      data-trigger=""
                                      name="product"
                                      id="product"
                                      onChange={(event) => {
                                        handleChangePackage(event.target.value);

                                        event.target.value !==
                                          serviceProductId &&
                                          handleRemoveDraftItem('service');
                                      }}
                                    >
                                      <option>Select package</option>
                                      {products.product !== undefined &&
                                        products.product.length > 0 &&
                                        default_currency !== undefined &&
                                        products.product.map((product) => (
                                          <option
                                            value={product.hostbill_id}
                                            key={product.id}
                                          >
                                            {product.name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                )}

                                <p className="card-text mb-0">
                                  {new Intl.NumberFormat('en-US').format(
                                    filteredProduct !== null &&
                                      default_currency !== undefined
                                      ? ((12 * filteredProduct.renewal_price +
                                          24 *
                                            filteredProduct.renewal_price *
                                            (parseInt(currentPeriod) - 1)) /
                                          (24 * parseInt(currentPeriod))) *
                                          (default_currency.code !== 'FCFA'
                                            ? parseFloat(default_currency.rate)
                                            : 1)
                                      : 0
                                  )}{' '}
                                  {default_currency !== undefined &&
                                    default_currency.code}{' '}
                                  monthly (paid yearly)
                                </p>
                                <small className="text-muted">
                                  Renews at{' '}
                                  {new Intl.NumberFormat('en-US').format(
                                    (filteredProduct !== null &&
                                    default_currency !== undefined
                                      ? filteredProduct.price *
                                        (default_currency.code !== 'FCFA'
                                          ? parseFloat(default_currency.rate)
                                          : 1)
                                      : 0) *
                                      12 *
                                      2
                                  )}{' '}
                                  {default_currency !== undefined &&
                                    default_currency.code}{' '}
                                  per year
                                </small>
                                <hr className="w-100 mt-3 mb-4" />
                              </div>

                              <div className="item">
                                <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                  <div>
                                    <span>Duration</span>
                                    <span className="ml-3">
                                      <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          setShowPeriodField(!showPeriodField);
                                        }}
                                        className="badge badge-primary rounded-pill"
                                      >
                                        {showPeriodField === false ? (
                                          'Change'
                                        ) : (
                                          <BsXLg />
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                  <span className="font-weight-bold text-uppercase">
                                    {currentPeriod} year(s)
                                  </span>
                                </h6>
                                {showPeriodField === true && (
                                  <div>
                                    <select
                                      className="form-control form-control-sm col-md-5 custom-select"
                                      data-trigger=""
                                      name="period"
                                      id="period"
                                      onChange={(event) => {
                                        if (
                                          parseInt(event.target.value) !==
                                          parseInt(currentPeriod)
                                        ) {
                                          handleChangePeriod(
                                            parseInt(event.target.value)
                                          );
                                          handleRemoveDraftItem('domain');
                                        }

                                        setChangeBtn('period');
                                      }}
                                    >
                                      <option>Select years</option>
                                      <option value={1}>
                                        12 months (1 year)
                                      </option>
                                      <option value={2}>
                                        24 months (2 years)
                                      </option>
                                    </select>
                                  </div>
                                )}

                                <hr className="w-100 mt-3 mb-4" />
                              </div>

                              <div className="item">
                                <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                  <div>
                                    <span>Domain name</span>
                                    {hasDomain === false && (
                                      <span className="ml-3">
                                        <span
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            setModal(true);
                                          }}
                                          className="badge badge-primary rounded-pill"
                                        >
                                          Change
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                  {hasDomain === false && (
                                    <span className="font-weight-bold text-uppercase">
                                      FREE
                                    </span>
                                  )}
                                </h6>
                                <p className="card-text mb-0">
                                  {currentDomain !== null
                                    ? currentDomain
                                    : 'NAN'}
                                  {hasDomain === true && (
                                    <span className="ml-3">
                                      <span className="badge badge-success rounded-pill">
                                        Pre owned
                                      </span>
                                    </span>
                                  )}
                                </p>
                                <hr className="w-100 mt-3 mb-4" />
                              </div>

                              <div className="total">
                                <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                  <span>Total</span>
                                  <span className="font-weight-bold text-uppercase">
                                    {new Intl.NumberFormat('en-US').format(
                                      filteredProduct !== null &&
                                        default_currency !== undefined
                                        ? ((12 * filteredProduct.renewal_price +
                                            24 *
                                              filteredProduct.renewal_price *
                                              (parseInt(currentPeriod) - 1)) /
                                            2) *
                                            (default_currency.code !== 'FCFA'
                                              ? parseFloat(
                                                  default_currency.rate
                                                )
                                              : 1)
                                        : 0
                                    )}{' '}
                                    {default_currency !== undefined &&
                                      default_currency.code}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          ))}
                    </div>
                  )}

                  {dProduct !== null && (
                    <div className="card">
                      {domainDetails !== undefined &&
                        domainDetails !== null &&
                        dProduct !== null &&
                        dProduct.hasOwnProperty('name') &&
                        domainDetails.tld === dProduct.name &&
                        default_currency !== undefined && (
                          <div
                            className="card-body pb-4 pt-4"
                            key={domainDetails.product_id}
                          >
                            <h6 className="text-left text-uppercase font-weight-bold mb-3">
                              Order Summary
                            </h6>
                            <hr className="w-100 mb-4 m-0" />

                            <div className="item">
                              <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                <div>
                                  <span>
                                    {domainDetails !== null
                                      ? domainDetails.name
                                      : 'NAN'}
                                  </span>
                                  <span className="ml-3">
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        navigate('/solo/search-domain');
                                      }}
                                      className="badge badge-primary rounded-pill"
                                    >
                                      {showPackagesField === false ? (
                                        'Change'
                                      ) : (
                                        <BsXLg />
                                      )}
                                    </span>
                                  </span>
                                </div>

                                <span className="font-weight-bold text-uppercase">
                                  {new Intl.NumberFormat('en-US').format(
                                    domainDetails !== null &&
                                      dProduct !== null &&
                                      default_currency !== undefined
                                      ? dProduct.register *
                                          parseInt(currentPeriod) *
                                          (default_currency.code !== 'FCFA'
                                            ? parseFloat(default_currency.rate)
                                            : 1)
                                      : 0
                                  )}{' '}
                                  {default_currency !== undefined &&
                                    default_currency.code}
                                </span>
                              </h6>

                              <hr className="w-100 mt-3 mb-4" />
                            </div>

                            <div className="item">
                              <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                <div>
                                  <span>Duration</span>
                                  <span className="ml-3">
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setShowPeriodField(!showPeriodField);
                                      }}
                                      className="badge badge-primary rounded-pill"
                                    >
                                      {showPeriodField === false ? (
                                        'Change'
                                      ) : (
                                        <BsXLg />
                                      )}
                                    </span>
                                  </span>
                                </div>
                                <span className="font-weight-bold text-uppercase">
                                  {currentPeriod} year(s)
                                </span>
                              </h6>
                              {showPeriodField === true && (
                                <div>
                                  <select
                                    className="form-control form-control-sm col-md-5 custom-select"
                                    data-trigger=""
                                    name="period"
                                    id="period"
                                    onChange={(event) => {
                                      if (
                                        parseInt(event.target.value) !==
                                        parseInt(currentPeriod)
                                      ) {
                                        handleChangePeriod(
                                          parseInt(event.target.value)
                                        );
                                        handleRemoveDraftItem('domain');
                                        setIsBuyingDomain(true);
                                      }

                                      setChangeBtn('period');
                                    }}
                                  >
                                    <option>Select years</option>
                                    <option value={1}>
                                      12 months (1 year)
                                    </option>
                                    <option value={2}>
                                      24 months (2 years)
                                    </option>
                                  </select>
                                </div>
                              )}

                              <hr className="w-100 mt-3 mb-4" />
                            </div>

                            <div className="total">
                              <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                <span>Total</span>
                                <span className="font-weight-bold text-uppercase">
                                  {new Intl.NumberFormat('en-US').format(
                                    domainDetails !== null &&
                                      dProduct !== null &&
                                      default_currency !== undefined
                                      ? dProduct.register *
                                          parseInt(currentPeriod) *
                                          (default_currency.code !== 'FCFA'
                                            ? parseFloat(default_currency.rate)
                                            : 1)
                                      : 0
                                  )}{' '}
                                  {default_currency !== undefined &&
                                    default_currency.code}
                                </span>
                              </h6>
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal}
          size="lg"
          toggle={toggle}
          centered
          modalTransition={{ timeout: 300 }}
        >
          <ModalHeader toggle={toggle}>Change domain</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12 m-auto">
                <ul
                  className="nav nav-pills nav-fill flex-column flex-md-row"
                  id="tabs-icons-text"
                  role="tablist"
                >
                  <li className="nav-item">
                    <Link
                      onClick={() => {
                        setCurrentMTab('need_domain');
                        ls.set('currentMTab', 'need_domain', { encrypt: true });
                      }}
                      className={`nav-link border rounded-pill ${
                        currentMTab === 'need_domain' ? 'active' : ''
                      }`}
                      data-toggle="tab"
                      to=""
                      role="tab"
                      aria-controls="plans"
                      aria-selected="true"
                    >
                      <p className="m-0 font-weight-500">
                        I need a domain name
                      </p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={() => {
                        setCurrentMTab('own_domain');
                        ls.set('currentMTab', 'own_domain', { encrypt: true });
                      }}
                      className={`nav-link border rounded-pill  ${
                        currentMTab === 'own_domain' ? 'active' : ''
                      }`}
                      data-toggle="tab"
                      to=""
                      role="tab"
                      aria-controls="domains"
                      aria-selected="false"
                    >
                      <p className="m-0 font-weight-500">
                        I already have a domain name{' '}
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-content">
              {currentMTab === 'need_domain' && (
                <div
                  className={`tab-pane fade ${
                    currentMTab === 'need_domain' ? 'active show' : ''
                  }`}
                  id="need_domain"
                >
                  <div className="row mt-4">
                    <div className="col-md-12 m-auto">
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleSearch}
                        enableReinitialize
                        validationSchema={searchValidationSchema}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          touched,
                          handleBlur,
                          errors,
                        }) => (
                          <form
                            className="code-validate"
                            action="POST"
                            onSubmit={handleSubmit}
                          >
                            <div className="input-group">
                              <input
                                style={{
                                  borderRadius:
                                    '50rem 0rem 0rem 50rem !important',
                                }}
                                type="text"
                                className="form-control p-4"
                                name="domain_name"
                                placeholder="Find your domain"
                                aria-label="domain"
                                value={values.domain_name}
                                onChange={handleChange('domain_name')}
                                onBlur={handleBlur('domain_name')}
                              />
                              <div className="input-group-append">
                                <select
                                  className="extension_selector"
                                  name="domain_ext"
                                  value={values.domain_ext}
                                  onChange={handleChange('domain_ext')}
                                  onBlur={handleBlur('domain_ext')}
                                >
                                  <option defaultValue>.example</option>
                                  {domain_ext.products !== undefined &&
                                    domain_ext.products.length > 0 &&
                                    domain_ext.products.map((ext) => (
                                      <option key={ext.id} value={ext.name}>
                                        {ext.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="input-group-append">
                                <button
                                  style={{
                                    borderRadius:
                                      '0rem 50rem 50rem 0rem !important',
                                  }}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {isLoading ? (
                                    <CircularProgress
                                      className={classes.loaderStyle}
                                    />
                                  ) : (
                                    'Search'
                                  )}
                                </button>
                              </div>
                            </div>
                            {touched.domain_name && errors.domain_name && (
                              <MsgText
                                text={errors.domain_name}
                                textColor="danger"
                              />
                            )}
                            {touched.domain_ext && errors.domain_ext && (
                              <MsgText
                                text={errors.domain_ext}
                                textColor="danger"
                              />
                            )}
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12 m-auto">
                      {searchResult !== null &&
                        searchResult.hasOwnProperty('domain_available') &&
                        searchResult.domain_available === 'yes' &&
                        isLoading === false && (
                          <div className="card mb-2 shadow-sm border-bottom">
                            <div className="card-body px-4 py-2">
                              <div className="row">
                                <div className="col-md-8 text-left">
                                  <p className="p-0 m-0">
                                    <strong>{searchResult.domain_name}</strong>{' '}
                                    is available for{' '}
                                    <span className="text-success">FREE</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {searchResult !== null &&
                        searchResult.hasOwnProperty('domain_available') &&
                        searchResult.domain_available === 'no' && (
                          <div className="card mb-2 shadow-sm border-bottom">
                            <div className="card-body px-4 py-2">
                              <div className="row">
                                <div className="col-md-8 text-left">
                                  <p className="p-0 m-0">
                                    <strong>{searchResult.domain_name}</strong>{' '}
                                    is not available
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>

                    {/* <div className="col-md-12 m-auto">
                            <div className="card mb-2 shadow-sm border-bottom">
                              <div className="card-body px-4 py-2">
                                <div className="row">
                                  <div className="col-md-8 text-left">
                                    <p className="p-0 m-0">
                                      <strong>domain-name.tv</strong> is
                                      available for{' '}
                                      <span className="text-warning">
                                        12,000 RWF per Year
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-4 text-right">
                                    <Link
                                      to=""
                                      className="btn btn-outline-primary btn-round btn-sm pl-3 pr-3"
                                    >
                                      Buy
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                  </div>
                </div>
              )}
              {currentMTab === 'own_domain' && (
                <div
                  className={`tab-pane fade ${
                    currentMTab === 'own_domain' ? 'active show' : ''
                  }`}
                  id="own_domain"
                >
                  <div className="row mt-4">
                    <div className="col-md-12 m-auto">
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleOwnsDomain}
                        enableReinitialize
                        validationSchema={ownsDomainValidationSchema}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          touched,
                          handleBlur,
                          errors,
                        }) => (
                          <form
                            className="code-validate"
                            action="POST"
                            onSubmit={handleSubmit}
                          >
                            <div className="row mt-2">
                              <div className="form-group col-md-12">
                                <div className="input-group">
                                  <input
                                    style={{
                                      borderRadius:
                                        '50rem 0rem 0rem 50rem !important',
                                    }}
                                    type="text"
                                    className="form-control p-4"
                                    name="owned_domain_name"
                                    placeholder="Find your domain"
                                    aria-label="owned_domain_name"
                                    value={values.owned_domain_name}
                                    onChange={handleChange('owned_domain_name')}
                                    onBlur={handleBlur('owned_domain_name')}
                                  />

                                  <div className="input-group-append">
                                    <button
                                      onClick={() => {
                                        validateDomain(
                                          values.owned_domain_name
                                        );
                                      }}
                                      style={{
                                        borderRadius:
                                          '0rem 50rem 50rem 0rem !important',
                                      }}
                                      type="button"
                                      className="btn btn-primary"
                                    >
                                      Validate
                                    </button>
                                  </div>
                                </div>
                                {touched.owned_domain_name &&
                                  errors.owned_domain_name && (
                                    <MsgText
                                      text={errors.owned_domain_name}
                                      textColor="danger"
                                    />
                                  )}
                              </div>
                              {isValid && (
                                <>
                                  <div className="form-group col-md-6">
                                    <input
                                      type="text"
                                      className="form-control p-4"
                                      name="dns1"
                                      placeholder="Nameserver 1"
                                      aria-label="dns1"
                                      value={values.dns1}
                                      onChange={handleChange('dns1')}
                                      onBlur={handleBlur('dns1')}
                                    />
                                    {touched.dns1 && errors.dns1 && (
                                      <MsgText
                                        text={errors.dns1}
                                        textColor="danger"
                                      />
                                    )}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <input
                                      type="text"
                                      className="form-control p-4"
                                      name="dns2"
                                      placeholder="Nameserver 2"
                                      aria-label="dns2"
                                      value={values.dns2}
                                      onChange={handleChange('dns2')}
                                      onBlur={handleBlur('dns2')}
                                    />
                                    {touched.dns2 && errors.dns2 && (
                                      <MsgText
                                        text={errors.dns2}
                                        textColor="danger"
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          {searchResult !== null &&
            searchResult.hasOwnProperty('domain_available') &&
            searchResult.domain_available === 'yes' &&
            isLoading === false && (
              <ModalFooter style={{ padding: '13px' }}>
                <Button
                  className="btn btn-primary btn-round btn-md w-30"
                  color="primary"
                  onClick={() => {
                    setModal(!modal);
                    ls.set('chosen_domain', searchResult.domain_name, {
                      encrypt: true,
                    });
                    handleRemoveDraftItem('all');
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            )}
        </Modal>
      </section>
    </>
  );
};
