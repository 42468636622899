import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { clearErrors } from '../redux/actions/errors';
import { CheckDomain } from '../redux/actions/domain';
import { AddOrderDraftItem } from '../redux/actions/order';

import { MsgText } from './MsgText';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { domain_ext } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loaderStyle: {
    width: '20px !important',
    height: '20px !important',
    color: '#fff !important',
    margin: '0px auto !important',
  },
  innerLoaderStyle: {
    width: '20px !important',
    height: '20px !important',
    color: '#4580F9 !important',
    margin: '0px auto !important',
  },
}));

export const SearchDomainSection = () => {
  let initialValues = {
    domain_name: '',
    owned_domain_name: '',
    domain_ext: '',
  };
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState(null);
  const [searchingFor, setSearchingFor] = useState(null);
  const [currentProd, setCurrentProd] = useState(null);
  const [currentTab, setCurrentTab] = useState('need_domain');
  const [isValid, setIsValid] = useState(false);
  const [request, setRequest] = useState('all');
  const [isAvailable, setIsAvailable] = useState(null);
  const [innerLoader, setInnerLoader] = useState(false);
  const dispatch = useDispatch();
  const { default_currency } = useSelector((state) => state.currency);
  const { domain, domain_period, domain_message } = useSelector(
    (state) => state.domain
  );
  const { order_draft, order_message } = useSelector((state) => state.order);
  const { isLoading } = useSelector((state) => state.loader);
  const { error_msg } = useSelector((state) => state.errors);

  useEffect(() => {
    let single_product = ls.get('chosen_product', { decrypt: true });
    setCurrentProd(single_product);
    ls.set('currentTab', 'need_domain', { encrypt: true });
    ls.set('has_domain', false, { encrypt: true });
    setRequest('all');
  }, []);

  useEffect(() => {
    if (domain) {
      setSearchResult({ ...domain, domain_name: searchingFor });
    }
  }, [domain, searchingFor]);

  const searchValidationSchema = Yup.object().shape({
    domain_name: Yup.string()
      .required()
      .trim()
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        'Only numbers & alphabets are allowed for this field'
      ),
    domain_ext: Yup.string().required().label('Domain extension'),
  });

  // All form validations
  const ownsDomainValidationSchema = Yup.object().shape({
    owned_domain_name: Yup.string().required().label('Domain name'),
  });

  // All form handles
  const handleSearch = (values, onSubmitProps, ...props) => {
    let domain_name = `${values.domain_name}${values.domain_ext}`;
    SaveExtId(values.domain_ext);
    SaveHost(values.domain_name);
    setSearchingFor(domain_name);
    ls.set('chosen_domain', domain_name, { encrypt: true });
    dispatch(CheckDomain(domain_name));
  };
  const handleOwnsDomain = (values, onSubmitProps) => {
    ls.set('chosen_domain', values.owned_domain_name, { encrypt: true });
    if (request !== 'service') {
      return;
    }
    ls.set('has_domain', true, { encrypt: true });
    HandleAddOrderDraftItem();
  };

  const validateDomain = (domain_name) => {
    SaveHost(domain_name.split('.')[0]);
    setSearchingFor(domain_name);
    dispatch(CheckDomain(domain_name));
  };

  useEffect(() => {
    if (searchResult !== null && searchResult.domain_available === 'no') {
      setIsValid(true);
    }
  }, [searchResult]);

  const SaveExtId = (ext) => {
    ls.set(
      'chosen_domain_extension',
      domain_ext.products.filter((product) => product.name === ext),
      { encrypt: true }
    );
    ls.set('chosen_ext', ext, { encrypt: true });
  };

  const SaveHost = (host) => {
    ls.set('chosen_host', host, { encrypt: true });
  };

  const HandleAddOrderDraftItem = () => {
    setInnerLoader(true);
    let chosen_domain_details = ls.get('chosen_domain', { decrypt: true });
    let chosen_host = ls.get('chosen_host', { decrypt: true });

    if (
      chosen_domain_details !== undefined &&
      chosen_domain_details !== '' &&
      chosen_host !== undefined &&
      chosen_host !== ''
    ) {
      let data = {
        draft_id: ls.get('order_draft', { decrypt: true }),
        product_id: ls.get('chosen_product', { decrypt: true })[0].hostbill_id,
        cycle: 'a',
        domain_name: ls.get('chosen_domain', { decrypt: true }),
        domain_period: domain_period !== undefined ? domain_period : 1,
        domain_action: 'register',
        requests: request,
      };
      if (currentTab !== 'own_domain') {
        dispatch(
          AddOrderDraftItem({
            ...data,
            domain_sld: ls.get('chosen_host', { decrypt: true }),
            domain_tld: parseInt(
              ls.get('chosen_domain_extension', { decrypt: true })[0].id
            ),
          })
        );
      } else {
        dispatch(AddOrderDraftItem(data));
      }
    }
  };

  useEffect(() => {
    if (order_message === 'added') {
      setInnerLoader(false);

      navigate('/order');
    }
  }, [order_message]);

  return (
    <>
      <section className="section">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-4">
              <div className="card card-pricing bg-white text-center mb-4">
                <div className="card-header bg-transparent">
                  <span className="badge badge-lg badge-pill btn btn-outline-primary my-2 p-1 text-uppercase w-100">
                    Plan Chosen
                  </span>
                  <h5 className="font-weight-bold text-uppercase ls-1 pt-0 pb-3 mb-0">
                    {' '}
                    {currentProd !== null ? currentProd[0].name : 'NAN'}
                  </h5>
                </div>
                <div className="card-body">
                  <h6 className="text-muted text-center">
                    <span className="badge badge-pill badge-md badge-success mr-1 text-uppercase">
                      Save 50%
                    </span>
                    <span>First Year</span>
                  </h6>
                  <div className="display-2 d-flex justify-content-center ">
                    {' '}
                    <span className="font-weight-bold ">
                      {new Intl.NumberFormat('en-US').format(
                        currentProd !== null && default_currency !== undefined
                          ? currentProd[0].price *
                              (default_currency.code !== 'FCFA'
                                ? parseFloat(default_currency.rate)
                                : 1)
                          : 0
                      )}
                    </span>{' '}
                    <span
                      className="font-weight-400 pt-3 text-left"
                      style={{ fontSize: '18px', lineHeight: '1' }}
                    >
                      {default_currency !== undefined && default_currency.code}{' '}
                      <br />
                      Monthly
                    </span>
                  </div>

                  <ul className="list-unstyled my-2">
                    <li className="align-items-center">
                      Renews at{' '}
                      <strong>
                        {new Intl.NumberFormat('en-US').format(
                          (currentProd !== null &&
                          default_currency !== undefined
                            ? currentProd[0].price *
                              (default_currency.code !== 'FCFA'
                                ? parseFloat(default_currency.rate)
                                : 1)
                            : 0) * 2
                        )}{' '}
                        <sup style={{ fontSize: '13px' }}>
                          {default_currency !== undefined &&
                            default_currency.code}
                        </sup>
                      </strong>{' '}
                      monthly
                    </li>
                  </ul>
                </div>
              </div>
              <Link
                to="/"
                className="btn btn-outline-primary btn-round btn-lg w-100"
              >
                Change Plan
              </Link>
            </div>
            <div className="col-md-8">
              <div className="card card-pricing bg-white text-center mb-4">
                <div className="card-header bg-transparent">
                  <h2 className="font-weight-bold text-uppercase ls-1 pt-3 ">
                    {' '}
                    Add a Free *Domain Name
                  </h2>
                  <div className="row">
                    <div className="col-md-8 m-auto">
                      {/* <!-- <p>Your domain will be your website's address. You can creat a new domain or use one you already own.</p> --> */}
                      <p className="my-0 f-8px pb-3">
                        * Free domains include:{' '}
                        <strong className="font-weight-bold">.cm</strong>,{' '}
                        <strong className="font-weight-bold">.rw</strong>,{' '}
                        <strong className="font-weight-bold">.com</strong>,{' '}
                        <strong className="font-weight-bold">.net</strong>. If
                        you cancel your hosting account in the first term a
                        non-refundable domain fee will be applied.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 m-auto">
                      <ul
                        className="nav nav-pills nav-fill flex-column flex-md-row"
                        id="tabs-icons-text"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <Link
                            onClick={() => {
                              setCurrentTab('need_domain');
                              setRequest('all');
                              ls.set('currentTab', 'need_domain', {
                                encrypt: true,
                              });
                            }}
                            className={`nav-link border rounded-pill ${
                              currentTab === 'need_domain' ? 'active' : ''
                            }`}
                            data-toggle="tab"
                            to=""
                            role="tab"
                            aria-controls="plans"
                            aria-selected="true"
                          >
                            <p className="m-0 font-weight-bold">
                              I need a domain name
                            </p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={() => {
                              setCurrentTab('own_domain');
                              setRequest('service');
                              ls.set('currentTab', 'own_domain', {
                                encrypt: true,
                              });
                            }}
                            className={`nav-link border rounded-pill  ${
                              currentTab === 'own_domain' ? 'active' : ''
                            }`}
                            data-toggle="tab"
                            to=""
                            role="tab"
                            aria-controls="domains"
                            aria-selected="false"
                          >
                            <p className="m-0 font-weight-bold">
                              I already have a domain name
                            </p>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content">
                    {currentTab === 'need_domain' && (
                      <div
                        className={`tab-pane fade ${
                          currentTab === 'need_domain' ? 'active show' : ''
                        }`}
                        id="need_domain"
                      >
                        <div className="row mt-4">
                          <div className="col-md-12 m-auto">
                            <Formik
                              initialValues={initialValues}
                              onSubmit={handleSearch}
                              enableReinitialize
                              validationSchema={searchValidationSchema}
                            >
                              {({
                                values,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                touched,
                                handleBlur,
                                errors,
                              }) => (
                                <form
                                  className="code-validate"
                                  action="POST"
                                  onSubmit={handleSubmit}
                                >
                                  <div className="input-group">
                                    <input
                                      style={{
                                        borderRadius:
                                          '50rem 0rem 0rem 50rem !important',
                                      }}
                                      type="text"
                                      className="form-control p-4"
                                      name="domain_name"
                                      placeholder="Find your domain"
                                      aria-label="domain"
                                      value={values.domain_name}
                                      onChange={handleChange('domain_name')}
                                      onBlur={handleBlur('domain_name')}
                                    />
                                    <div className="input-group-append">
                                      <select
                                        className="extension_selector"
                                        name="domain_ext"
                                        value={values.domain_ext}
                                        onChange={handleChange('domain_ext')}
                                        onBlur={handleBlur('domain_ext')}
                                      >
                                        <option defaultValue>.example</option>
                                        {domain_ext.products !== undefined &&
                                          domain_ext.products.length > 0 &&
                                          domain_ext.products.map((ext) => (
                                            <option
                                              key={ext.id}
                                              value={ext.name}
                                            >
                                              {ext.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <div className="input-group-append">
                                      <button
                                        style={{
                                          borderRadius:
                                            '0rem 50rem 50rem 0rem !important',
                                        }}
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        {isLoading ? (
                                          <CircularProgress
                                            className={classes.loaderStyle}
                                          />
                                        ) : (
                                          'Search'
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  {touched.domain_name &&
                                    errors.domain_name && (
                                      <MsgText
                                        text={errors.domain_name}
                                        textColor="danger"
                                      />
                                    )}
                                  {touched.domain_ext && errors.domain_ext && (
                                    <MsgText
                                      text={errors.domain_ext}
                                      textColor="danger"
                                    />
                                  )}
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12 m-auto">
                            {searchResult !== null &&
                              searchResult.hasOwnProperty('domain_available') &&
                              searchResult.domain_available === 'yes' &&
                              isLoading === false && (
                                <div className="card mb-2 shadow-sm border-bottom">
                                  <div className="card-body px-4 py-2">
                                    <div className="row">
                                      <div className="col-md-8 text-left">
                                        <p className="p-0 m-0">
                                          <strong>
                                            {searchResult.domain_name}
                                          </strong>{' '}
                                          is available for{' '}
                                          <span className="text-success">
                                            FREE
                                          </span>
                                        </p>
                                      </div>
                                      <div className="col-md-4 text-right">
                                        {innerLoader === false ? (
                                          <button
                                            onClick={() => {
                                              ls.set(
                                                'chosen_domain',
                                                searchResult.domain_name,
                                                { encrypt: true }
                                              );
                                              HandleAddOrderDraftItem();
                                            }}
                                            className="btn btn-outline-primary btn-round btn-sm pl-3 pr-3"
                                          >
                                            Choose
                                          </button>
                                        ) : (
                                          <CircularProgress
                                            className={classes.innerLoaderStyle}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {searchResult !== null &&
                              searchResult.hasOwnProperty('domain_available') &&
                              searchResult.domain_available === 'no' && (
                                <div className="card mb-2 shadow-sm border-bottom">
                                  <div className="card-body px-4 py-2">
                                    <div className="row">
                                      <div className="col-md-8 text-left">
                                        <p className="p-0 m-0">
                                          <strong>
                                            {searchResult.domain_name}
                                          </strong>{' '}
                                          is not available
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                    {currentTab === 'own_domain' && (
                      <div
                        className={`tab-pane fade ${
                          currentTab === 'own_domain' ? 'active show' : ''
                        }`}
                        id="own_domain"
                      >
                        <div className="row">
                          <div className="col-md-12 m-auto">
                            <Formik
                              initialValues={initialValues}
                              onSubmit={handleOwnsDomain}
                              enableReinitialize
                              validationSchema={ownsDomainValidationSchema}
                            >
                              {({
                                values,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                touched,
                                handleBlur,
                                errors,
                              }) => (
                                <form
                                  className="code-validate"
                                  action="POST"
                                  onSubmit={handleSubmit}
                                >
                                  <div className="row mt-4">
                                    <div className="form-group col-md-12">
                                      <div className="input-group">
                                        <input
                                          style={{
                                            borderRadius:
                                              '50rem 0rem 0rem 50rem !important',
                                          }}
                                          type="text"
                                          className="form-control p-4"
                                          name="owned_domain_name"
                                          placeholder="Find your domain"
                                          aria-label="owned_domain_name"
                                          value={values.owned_domain_name}
                                          onChange={handleChange(
                                            'owned_domain_name'
                                          )}
                                          onBlur={handleBlur(
                                            'owned_domain_name'
                                          )}
                                        />

                                        <div className="input-group-append">
                                          <button
                                            onClick={() => {
                                              validateDomain(
                                                values.owned_domain_name
                                              );
                                            }}
                                            style={{
                                              borderRadius:
                                                '0rem 50rem 50rem 0rem !important',
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                          >
                                            {isLoading ? (
                                              <CircularProgress
                                                className={classes.loaderStyle}
                                              />
                                            ) : (
                                              'Check availability '
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                      {touched.owned_domain_name &&
                                        errors.owned_domain_name && (
                                          <>
                                            <MsgText
                                              text={errors.owned_domain_name}
                                              textColor="danger"
                                            />
                                            <br />
                                          </>
                                        )}
                                      {searchResult !== null &&
                                        searchResult.domain_available ===
                                          'yes' &&
                                        isLoading === false && (
                                          <div className="card mb-2 mt-3 shadow-sm border-bottom">
                                            <div className="card-body px-4 py-2">
                                              <div className="row">
                                                <div className="col-md-8 text-left">
                                                  <p className="p-0 m-0 text-default">
                                                    Oops... It looks like no one
                                                    owns{' '}
                                                    <strong className="font-weight-bold">
                                                      {searchResult.domain_name}
                                                    </strong>
                                                    ! However you can
                                                  </p>
                                                </div>
                                                <div className="col-md-4 text-right">
                                                  {innerLoader === false ? (
                                                    <button
                                                      onClick={() => {
                                                        ls.set(
                                                          'chosen_domain',
                                                          searchResult.domain_name,
                                                          { encrypt: true }
                                                        );
                                                        HandleAddOrderDraftItem();
                                                      }}
                                                      className="btn btn-outline-primary btn-round btn-sm pl-3 pr-3"
                                                    >
                                                      Add it to your plan
                                                    </button>
                                                  ) : (
                                                    <CircularProgress
                                                      className={
                                                        classes.innerLoaderStyle
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  {searchResult !== null &&
                                    searchResult.domain_available === 'no' && (
                                      <div className="col-md-4 mt-4 ml-auto text-right">
                                        <button
                                          type="submit"
                                          className="btn btn-outline-primary btn-round pl-3 pr-3"
                                        >
                                          Continue
                                        </button>
                                      </div>
                                    )}
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
