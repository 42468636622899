import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { useSelector, useDispatch } from 'react-redux';
import { GetCurrencies, SetDefaultCurrency } from '../redux/actions/currency';

export const PageLoader = () => {
  const [wasCalled, setWasCalled] = useState(false);
  const dispatch = useDispatch();
  const { currencies } = useSelector((state) => state.currency);

  useEffect(() => {
    let defaultExists = ls.get('default_currency', { decrypt: true });
    if (defaultExists === null) {
      dispatch(GetCurrencies());
    }
  }, []);

  useEffect(() => {
    if (
      currencies.currencies !== undefined &&
      currencies.currencies.length > 0
    ) {
      const api_key_1 = '89e180b0-9e15-11ec-9d58-53a5b438ae89';
      //const api_key_2 = 'a4c1cbc0-9e11-11ec-a8dd-dbd456fd843e';
      let chosenCurrency = ls.get('default_currency', { decrypt: true });

      if (chosenCurrency === null && wasCalled === false) {
        fetch(`https://api.freegeoip.app/json/?apikey=${api_key_1}`)
          .then(function (response) {
            return response.json();
          })
          .then(function (payload) {
            setWasCalled(true);
            currencies.currencies !== undefined &&
              currencies.currencies.length > 0 &&
              currencies.currencies
                .filter((currency) => {
                  return currency.country_code === payload.country_code;
                })
                .map((filteredCurrency) => {
                  if (filteredCurrency.hasOwnProperty('code')) {
                    dispatch(
                      SetDefaultCurrency({
                        ...filteredCurrency,
                        city: payload.region_name,
                      })
                    );
                  } else {
                    let newCurrency = {
                      id: 1,
                      code: 'USD',
                      country_code: payload.country_code,
                      sign: '$',
                      iso: 'USD',
                      rate: '0.002000000',
                      last_changes: '2022-02-05 14:52:17',
                      update: '1',
                      enable: '1',
                      country: payload.country_name,
                      city: payload.region_name,
                      format: '1,234.56',
                      created_at: '2022-03-04T14:07:18.000000Z',
                      updated_at: '2022-03-04T14:07:18.000000Z',
                    };
                    dispatch(SetDefaultCurrency(newCurrency));
                  }
                });
          });
      } else {
        dispatch(SetDefaultCurrency(chosenCurrency));
      }
    }
  }, [currencies]);
  return <></>;
};
