import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { SignUp, clearMessage, GetClientDetails } from '../redux/actions/auth';
import { EditOrderDraft, GetOrderDraft } from '../redux/actions/order';
import { clearErrors } from '../redux/actions/errors';
import { MsgText } from './MsgText';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GetProducts } from '../redux/actions/product';
import { countries } from '../constants';
import { GetCurrencies, SetDefaultCurrency } from '../redux/actions/currency';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loaderStyle: {
    width: '20px !important',
    height: '20px !important',
    color: '#fff !important',
    margin: '0px auto !important',
  },
  _loaderStyle: {
    width: '50px !important',
    height: '50px !important',
    color: '#4580F9 !important',
    margin: '170px auto !important',
    zIndex: 1,
    position: 'absolute !important',
  },

  cardMask: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: '#fff',
    filter: 'blur(8px)',
  },
  center: {
    margin: 'auto',
    width: '10%',
  },
}));

export const CompleteOrderSection = () => {
  const localExts = ['.rw', '.org.rw', '.co.rw', '.ac.rw', '.net.rw', '.cm'];

  let default_currency_details = ls.get('default_currency', { decrypt: true });
  let initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    password_confirmation: '',
    country: `${default_currency_details.country}-${default_currency_details.country_code}`,
    phone: '',
    city: `${default_currency_details.city}`,
    postcode: '',
    address1: '',
    type_account: 'company',
    company_name: '',
    state: `${default_currency_details.city}`,
    country_iso: `${default_currency_details.country_code}`,
  };

  const config = {
    // TEST KEYS
    // public_key: 'FLWPUBK_TEST-9cb41850dd8dbab51a975e43b40ebacc-X',
    // tx_ref: 'FLWSECK_TEST-5ae31a92cf984d0502060d2d728d6c2c-X',

    // RW LIVE KEYS
    // public_key: 'FLWPUBK-2e2c8a85f9c212d601ffa766f6cc17de-X',
    // tx_ref: 'FLWSECK-f0844722129c0e04e3830d1fc2ff524b-X',

    // CM LIVE KEYS
    public_key: 'FLWPUBK-7f96cbb3f14b363e8b1b8dc9998f4a9c-X',
    tx_ref: 'FLWSECK-aeaaf6eff124cfe1c59af329ea16879a-X',
    amount: 0,
    currency: '',
    country: '',
    payment_options:
      'account, card, banktransfer, mpesa, mobilemoneyrwanda, mobilemoneyzambia, qr, mobilemoneyuganda, ussd, credit, barter, mobilemoneyghana, payattitude, mobilemoneyfranco, paga, 1voucher, mobilemoneytanzania',
    customer: {
      email: '',
      phonenumber: '',
      name: '',
    },
    customizations: {
      title: 'Hosting payment',
      description: 'Payment for hosting service',
      logo: 'http://cloudhost.rw/assets-cdn/images/cloudhost-icon.png',
    },
  };

  const [flutterwaveData, setFlutterwaveData] = useState(config);

  const handleFlutterPayment = useFlutterwave(flutterwaveData);
  const classes = useStyles();
  const navigate = useNavigate();
  const { default_currency, currencies } = useSelector(
    (state) => state.currency
  );
  const { products } = useSelector((state) => state.product);
  const { _isLoading } = useSelector((state) => state.loader);
  const { domain_message } = useSelector((state) => state.domain);
  const { client_details, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const { order } = useSelector((state) => state.order);
  const [currentFormData, setCurrentFormData] = useState(null);
  const [formValue, setFormValue] = useState(initialValues);
  const [currentPeriod, setCurrentPeriod] = useState(2);
  const [currentDomain, setCurrentDomain] = useState(null);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [serviceProductId, setServiceProductId] = useState();
  const [domainProductId, setDomainProductId] = useState();
  const [showCompanyNameField, setShowCompanyNameField] = useState(true);
  const [usePreInfo, setUserPreInfo] = useState(null);
  const [hasDomain, setHasDomain] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [justLoggedIn, setJustLoggedIn] = useState(false);
  const [value, setValue] = useState();
  const [domainDetails, setDomainDetails] = useState(false);
  const [dProduct, setDProduct] = useState(null);

  const [canPay, setCanPay] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canPay === true) {
      let client = ls.get('client', { decrypt: true });
      let draft_id = ls.get('order_draft', { decrypt: true });
      if (draft_id && draft_id !== null && draft_id !== undefined) {
        let data = {
          client_id: client.client_id,
          draft_id,
        };
        handleEditOrderDraft(data);
      }
      dispatch(GetClientDetails({ client_id: client.client_id }));
    }
  }, [canPay]);

  useEffect(() => {
    let UserPreInfo = ls.get('UserPreInfo', { decrypt: true });
    let has_domain = ls.get('has_domain', { decrypt: true });
    let just_loggedIn = ls.get('just_loggedIn', { decrypt: true });
    let domain_details = ls.get('domain_details', { decrypt: true });
    if (domain_details !== null && domain_details.hasOwnProperty('name')) {
      setDProduct(domain_details);
    }

    if (
      just_loggedIn &&
      just_loggedIn !== null &&
      just_loggedIn !== undefined &&
      just_loggedIn === true
    ) {
      setJustLoggedIn(true);
    }
    setHasDomain(has_domain);
    setUserPreInfo(UserPreInfo);
    dispatch(GetProducts());

    if (UserPreInfo !== null && UserPreInfo.hasOwnProperty('email')) {
      setFormValue({
        ...initialValues,
        email: UserPreInfo.email,
        firstname: UserPreInfo.firstname,
        lastname: UserPreInfo.lastname,
      });
    }

    if (default_currency.hasOwnProperty('code') === false) {
      let current_country = ls.get('current_country', { decrypt: true });
      setCurrentCountry(current_country);
      if (current_country !== null || current_country !== undefined) {
        dispatch(GetCurrencies());
      }
    }
  }, []);

  useEffect(() => {
    let draft_id = ls.get('order_draft', { decrypt: true });
    if (draft_id && draft_id !== null && draft_id !== undefined) {
      dispatch(GetOrderDraft(draft_id));
    }
  }, []);

  useEffect(() => {
    let current_currency = ls.get('current_currency', { decrypt: true });
    if (
      current_currency &&
      (current_currency === null || current_currency === undefined)
    ) {
      if (currencies) {
        currencies.currencies !== undefined &&
          currencies.currencies.length > 0 &&
          currentCountry !== null &&
          currencies.currencies
            .filter((currency) => {
              return currency.country_code === currentCountry;
            })
            .map((filteredCurrency) => {
              if (filteredCurrency.hasOwnProperty('code')) {
                dispatch(SetDefaultCurrency(filteredCurrency));
              } else {
                let newCurrency = {
                  id: 1,
                  code: 'USD',
                  country_code: currentCountry,
                  sign: '$',
                  iso: 'USD',
                  rate: '0.002000000',
                  last_changes: '2022-02-05 14:52:17',
                  update: '1',
                  enable: '1',
                  country: currentCountry,
                  format: '1,234.56',
                  created_at: '2022-03-04T14:07:18.000000Z',
                  updated_at: '2022-03-04T14:07:18.000000Z',
                };
                dispatch(SetDefaultCurrency(newCurrency));
              }
            });
      }
    }
  }, [currencies]);

  useEffect(() => {
    if (order) {
      if (order.success === false) {
        navigate('/');
        return;
      }
      if (order.draft !== undefined) {
        let obj_key = Object.keys(order.draft.services);
        let _obj_key = Object.keys(order.draft.domains);
        ls.set(
          'chosen_product_id',
          order.draft.services.hasOwnProperty('1')
            ? order.draft.services[obj_key[0]].product_id
            : '',
          { encrypt: true }
        );
        setServiceProductId(
          order.draft.services.hasOwnProperty('1')
            ? order.draft.services[obj_key[0]].product_id
            : ''
        );
        setCurrentPeriod(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].period
            : 1
        );
        ls.set(
          'current_period',
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].period
            : 1,
          { encrypt: true }
        );

        setCurrentDomain(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].name
            : order.draft.services[obj_key[0]].domain
        );
        ls.set(
          'chosen_ext',
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].tld
            : '',
          { encrypt: true }
        );

        setDomainDetails(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]]
            : ''
        );

        setDomainProductId(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]]
            : ''
        );

        let total = 0;
        if (order.draft.services.hasOwnProperty('1')) {
          products.product !== undefined &&
            products.product.length > 0 &&
            default_currency !== undefined &&
            products.product
              .filter((product) => product.hostbill_id === serviceProductId)
              .map((filteredProduct) => {
                total = parseFloat(
                  filteredProduct !== null && default_currency !== undefined
                    ? ((12 * filteredProduct.renewal_price +
                        24 *
                          filteredProduct.renewal_price *
                          (parseInt(currentPeriod) - 1)) /
                        2) *
                        (default_currency.code !== 'FCFA'
                          ? parseFloat(default_currency.rate)
                          : 1)
                    : 0
                );
              });
        } else {
          total = parseFloat(
            domainDetails !== null &&
              dProduct !== null &&
              default_currency !== undefined
              ? dProduct.register *
                  parseInt(currentPeriod) *
                  (default_currency.code !== 'FCFA'
                    ? parseFloat(default_currency.rate)
                    : 1)
              : 0
          );
        }

        if (total > 0) {
          setFlutterwaveData({
            ...flutterwaveData,
            amount: 100,
            currency: default_currency.code,
          });
        }
      }
    }
  }, [order, products, default_currency, currentPeriod]);

  // useEffect(() => {
  //   if (order) {
  //     if (order.success === false) {
  //       navigate('/');
  //     }
  //   }
  // }, [order, navigate]);

  useEffect(() => {
    if (client_details.hasOwnProperty('id')) {
      // Set customer info
      setFlutterwaveData({
        ...flutterwaveData,
        customer: {
          email: client_details.email,
          name: `${client_details.firstname} ${client_details.lastname}`,
          phonenumber: client_details.phonenumber.split(' ')[1],
        },
        country: client_details.country,
      });
    }
  }, [client_details]);

  // All Validations
  const formValidationSchema = Yup.object().shape({
    firstname: Yup.string().required().label('First name'),
    lastname: Yup.string().required().label('Last name'),
    email: Yup.string().required().email().label('Email'),
    country: Yup.string().required().label('Country'),
    city: Yup.string().required().label('City'),
    postcode: Yup.string().required().label('Post code'),
    phone: Yup.string().required().label('Phone'),
    address1: Yup.string().required().label('Street address1'),
    password: Yup.string()
      .required()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
      .label('Password'),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  const handleCreate = (values, onSubmitProps) => {
    dispatch(clearErrors());
    dispatch(clearMessage());
    dispatch(
      SignUp({
        ...values,
        country: values.country.split('-')[0],
        country_iso: values.country.split('-')[1],
      })
    );
    setCurrentFormData(onSubmitProps);
  };

  const handleEditOrderDraft = (data) => {
    dispatch(EditOrderDraft(data));
  };

  const handlePayment = (payment_res) => {
    if (payment_res.status === 'successful') {
      ls.set('hspyd', true, { encrypt: true });
      navigate('/setup');
    }
  };

  useEffect(() => {
    let isAuth = ls.get('isAuthenticated', { decrypt: true });
    if (isAuthenticated === true || isAuth === true) {
      setCanPay(true);
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   let draft_id = ls.get('order_draft', { decrypt: true });
  //   if (domain_message === 'domain registered') {
  //     dispatch(ConvertOrderDraft(draft_id));
  //   }
  // }, [domain_message]);

  return (
    <>
      <section className="section">
        <div className="container mt-5">
          <div className="row mb-4 mt-6">
            <div className="col-md-12">
              {canPay === true && (
                <h1 className="display-3 mb-0 text-center">
                  Complete your order
                </h1>
              )}
              {canPay === false && (
                <>
                  <h1 className="display-3 mb-0 text-center">{`Welcome back ${
                    usePreInfo !== null
                      ? usePreInfo.firstname + ' ' + usePreInfo.lastname
                      : 'Anonymous'
                  }!`}</h1>
                  <p className="mb-4 text-center">
                    Your order has been retrieved!
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="row">
            {canPay === false && (
              <div className="col-md-7">
                <div className="card card-pricing bg-white mb-4">
                  <div className="card-header bg-transparent">
                    <h2 className="font-weight-normal ls-1 pt-3">
                      {' '}
                      Complete your order
                    </h2>
                  </div>
                  <div className="card-body">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="create-pass"
                      >
                        <div className="row">
                          <div className="col-md-12 m-auto">
                            <p className="lead mt-0">
                              Complete your account creation process.
                            </p>
                            <Formik
                              enableReinitialize
                              initialValues={formValue}
                              onSubmit={handleCreate}
                              validationSchema={formValidationSchema}
                            >
                              {({
                                values,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                touched,
                                handleBlur,
                                errors,
                              }) => (
                                <form
                                  className="code-validate"
                                  action="POST"
                                  onSubmit={handleSubmit}
                                >
                                  <div className="form-group mb-3">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="ni ni-email-83"></i>
                                        </span>
                                      </div>
                                      <input
                                        className="form-control form-control-lg pl-2"
                                        placeholder="Email"
                                        type="email"
                                        disabled
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur('email')}
                                        autoComplete={`${true}`}
                                      />
                                    </div>
                                    {touched.email && errors.email && (
                                      <MsgText
                                        text={errors.email}
                                        textColor="danger"
                                      />
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="ni ni-lock-circle-open"></i>
                                        </span>
                                      </div>
                                      <input
                                        className="form-control form-control-lg"
                                        placeholder="Password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        onBlur={handleBlur('password')}
                                      />
                                    </div>
                                    {touched.password && errors.password && (
                                      <MsgText
                                        text={errors.password}
                                        textColor="danger"
                                      />
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="ni ni-lock-circle-open"></i>
                                        </span>
                                      </div>
                                      <input
                                        className="form-control form-control-lg"
                                        placeholder="Confirm Password"
                                        type="password"
                                        value={values.password_confirmation}
                                        onChange={handleChange(
                                          'password_confirmation'
                                        )}
                                        onBlur={handleBlur(
                                          'password_confirmation'
                                        )}
                                      />
                                    </div>
                                    {touched.password_confirmation &&
                                      errors.password_confirmation && (
                                        <MsgText
                                          text={errors.password_confirmation}
                                          textColor="danger"
                                        />
                                      )}
                                  </div>
                                  <hr className="w-100" />
                                  <div className="row">
                                    <div className="col-md-12">
                                      <h3 className="title mb-0 mt-0">
                                        Billing address
                                      </h3>
                                      <p className="lead">
                                        Billing details are important and are
                                        used to identify that you own a domain
                                        name.
                                      </p>
                                      <div className="row">
                                        <div className="col-md-6 mt-2">
                                          <div className="form-group">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="ni ni-single-02"></i>
                                                </span>
                                              </div>
                                              <input
                                                className="form-control form-control-lg"
                                                placeholder="Enter your first name"
                                                type="text"
                                                value={values.firstname}
                                                onChange={handleChange(
                                                  'firstname'
                                                )}
                                                onBlur={handleBlur('firstname')}
                                                autoComplete={`${true}`}
                                              />
                                            </div>
                                            {touched.firstname &&
                                              errors.firstname && (
                                                <MsgText
                                                  text={errors.firstname}
                                                  textColor="danger"
                                                />
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                          <div className="form-group">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="ni ni-single-02"></i>
                                                </span>
                                              </div>
                                              <input
                                                className="form-control form-control-lg"
                                                placeholder="Enter your last name"
                                                type="text"
                                                value={values.lastname}
                                                onChange={handleChange(
                                                  'lastname'
                                                )}
                                                onBlur={handleBlur('lastname')}
                                                autoComplete={`${true}`}
                                              />
                                            </div>
                                            {touched.lastname &&
                                              errors.lastname && (
                                                <MsgText
                                                  text={errors.lastname}
                                                  textColor="danger"
                                                />
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="ni ni-world"></i>
                                                </span>
                                              </div>
                                              <select
                                                className="form-control form-control-lg"
                                                data-trigger=""
                                                name="choices-single-default"
                                                id="choices-single-default"
                                                value={values.country}
                                                onChange={handleChange(
                                                  'country'
                                                )}
                                                onBlur={handleBlur('country')}
                                              >
                                                <option>Select country</option>
                                                {countries.map((country) => (
                                                  <option
                                                    key={country.code}
                                                    value={`${country.name}-${country.code}`}
                                                  >
                                                    {country.name}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                            {touched.country &&
                                              errors.country && (
                                                <MsgText
                                                  text={errors.country}
                                                  textColor="danger"
                                                />
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <div className="input-group">
                                              <PhoneInput
                                                className="form-control form-control-lg"
                                                international
                                                countryCallingCodeEditable={
                                                  false
                                                }
                                                defaultCountry={
                                                  values.country_iso
                                                }
                                                value={(values.phone = value)}
                                                onChange={setValue}
                                                onBlur={handleBlur('phone')}
                                                autoComplete={`${true}`}
                                              />
                                            </div>
                                            {touched.phone && errors.phone && (
                                              <MsgText
                                                text={errors.phone}
                                                textColor="danger"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div
                                          className={
                                            showCompanyNameField === true
                                              ? 'col-md-4'
                                              : `col-md-6`
                                          }
                                        >
                                          <div className="form-group">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="ni ni-single-02"></i>
                                                </span>
                                              </div>
                                              <select
                                                className="form-control form-control-lg"
                                                data-trigger=""
                                                name="choices-single-default"
                                                id="_choices-single-default"
                                                value={
                                                  (values.type_account =
                                                    values.type_account)
                                                }
                                                onChange={(e) => {
                                                  values.type_account =
                                                    e.target.value;
                                                  handleChange('type_account');

                                                  if (
                                                    e.target.value === 'company'
                                                  ) {
                                                    setShowCompanyNameField(
                                                      true
                                                    );
                                                  } else {
                                                    setShowCompanyNameField(
                                                      false
                                                    );
                                                  }
                                                }}
                                                onBlur={handleBlur(
                                                  'type_account'
                                                )}
                                              >
                                                <option>
                                                  Select Account type
                                                </option>
                                                <option value="company">
                                                  Company
                                                </option>
                                                <option value="private">
                                                  Private
                                                </option>
                                              </select>
                                            </div>
                                            {touched.type_account &&
                                              errors.type_account && (
                                                <MsgText
                                                  text={errors.type_account}
                                                  textColor="danger"
                                                />
                                              )}
                                          </div>
                                        </div>
                                        {showCompanyNameField === true && (
                                          <div
                                            className={
                                              showCompanyNameField === true
                                                ? 'col-md-4'
                                                : `col-md-6`
                                            }
                                          >
                                            <div className="form-group">
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">
                                                    <i className="ni ni-building"></i>
                                                  </span>
                                                </div>
                                                <input
                                                  className="form-control form-control-lg"
                                                  placeholder="Company name"
                                                  type="text"
                                                  value={values.company_name}
                                                  onChange={handleChange(
                                                    'company_name'
                                                  )}
                                                  onBlur={handleBlur(
                                                    'company_name'
                                                  )}
                                                  autoComplete={`${true}`}
                                                />
                                              </div>
                                              {touched.company_name &&
                                                errors.company_name && (
                                                  <MsgText
                                                    text={errors.company_name}
                                                    textColor="danger"
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        )}

                                        <div
                                          className={
                                            showCompanyNameField === true
                                              ? 'col-md-4'
                                              : `col-md-6`
                                          }
                                        >
                                          <div className="form-group">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="ni ni-building"></i>
                                                </span>
                                              </div>
                                              <input
                                                className="form-control form-control-lg"
                                                placeholder="City"
                                                type="text"
                                                value={values.city}
                                                onChange={handleChange('city')}
                                                onBlur={handleBlur('city')}
                                                autoComplete={`${true}`}
                                              />
                                            </div>
                                            {touched.city && errors.city && (
                                              <MsgText
                                                text={errors.city}
                                                textColor="danger"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="ni ni-building"></i>
                                                </span>
                                              </div>
                                              <input
                                                className="form-control form-control-lg"
                                                placeholder="State"
                                                type="text"
                                                value={values.state}
                                                onChange={handleChange('state')}
                                                onBlur={handleBlur('state')}
                                                autoComplete={`${true}`}
                                              />
                                            </div>
                                            {touched.state && errors.state && (
                                              <MsgText
                                                text={errors.state}
                                                textColor="danger"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="ni ni-map-big"></i>
                                                </span>
                                              </div>
                                              <input
                                                className="form-control form-control-lg"
                                                placeholder="Postcode/Zip"
                                                type="text"
                                                value={values.postcode}
                                                onChange={handleChange(
                                                  'postcode'
                                                )}
                                                onBlur={handleBlur('postcode')}
                                                autoComplete={`${true}`}
                                              />
                                            </div>
                                            {touched.postcode &&
                                              errors.postcode && (
                                                <MsgText
                                                  text={errors.postcode}
                                                  textColor="danger"
                                                />
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="ni ni-pin-3"></i>
                                                </span>
                                              </div>
                                              <input
                                                className="form-control form-control-lg"
                                                placeholder="Street address"
                                                type="text"
                                                value={values.address1}
                                                onChange={handleChange(
                                                  'address1'
                                                )}
                                                onBlur={handleBlur('address1')}
                                                autoComplete={`${true}`}
                                              />
                                            </div>
                                            {touched.address1 &&
                                              errors.address1 && (
                                                <MsgText
                                                  text={errors.address1}
                                                  textColor="danger"
                                                />
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 ml-auto text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-lg btn-primary btn-round pl-5 pr-5"
                                      >
                                        Create Account
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={canPay === true ? `col-md-5 m-auto` : `col-md-5`}>
              <div className={classes.orderSummary}>
                <div className={classes.center}>
                  {_isLoading === true && (
                    <CircularProgress className={classes._loaderStyle} />
                  )}
                </div>
                <div className={_isLoading === true ? classes.cardMask : ''}>
                  {order &&
                    order.draft !== undefined &&
                    order.draft.services.hasOwnProperty('1') && (
                      <div className="card">
                        {products.product !== undefined &&
                          products.product.length > 0 &&
                          default_currency !== undefined &&
                          products.product
                            .filter(
                              (product) =>
                                product.hostbill_id === serviceProductId
                            )
                            .map((filteredProduct) => (
                              <div
                                className="card-body pb-4 pt-4"
                                key={filteredProduct.id}
                              >
                                <h6 className="text-left text-uppercase font-weight-bold mb-3">
                                  Order Summary
                                </h6>
                                <hr className="w-100 mb-4 m-0" />

                                <div className="item">
                                  <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                    <div>
                                      <span>
                                        {filteredProduct !== null
                                          ? filteredProduct.name
                                          : 'NAN'}
                                      </span>
                                    </div>

                                    <span className="font-weight-bold text-uppercase">
                                      {new Intl.NumberFormat('en-US').format(
                                        filteredProduct !== null &&
                                          default_currency !== undefined
                                          ? ((12 *
                                              filteredProduct.renewal_price +
                                              24 *
                                                filteredProduct.renewal_price *
                                                (parseInt(currentPeriod) - 1)) /
                                              2) *
                                              (default_currency.code !== 'FCFA'
                                                ? parseFloat(
                                                    default_currency.rate
                                                  )
                                                : 1)
                                          : 0
                                      )}{' '}
                                      {default_currency !== undefined &&
                                        default_currency.code}
                                    </span>
                                  </h6>

                                  <p className="card-text mb-0">
                                    {new Intl.NumberFormat('en-US').format(
                                      filteredProduct !== null &&
                                        default_currency !== undefined
                                        ? ((12 * filteredProduct.renewal_price +
                                            24 *
                                              filteredProduct.renewal_price *
                                              (parseInt(currentPeriod) - 1)) /
                                            (24 * parseInt(currentPeriod))) *
                                            (default_currency.code !== 'FCFA'
                                              ? parseFloat(
                                                  default_currency.rate
                                                )
                                              : 1)
                                        : 0
                                    )}{' '}
                                    {default_currency !== undefined &&
                                      default_currency.code}{' '}
                                    monthly (paid yearly)
                                  </p>
                                  <small className="text-muted">
                                    Renews at{' '}
                                    {new Intl.NumberFormat('en-US').format(
                                      (filteredProduct !== null &&
                                      default_currency !== undefined
                                        ? filteredProduct.price *
                                          (default_currency.code !== 'FCFA'
                                            ? parseFloat(default_currency.rate)
                                            : 1)
                                        : 0) *
                                        12 *
                                        2
                                    )}{' '}
                                    {default_currency !== undefined &&
                                      default_currency.code}{' '}
                                    per year
                                  </small>
                                  <hr className="w-100 mt-3 mb-4" />
                                </div>

                                <div className="item">
                                  <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                    <div>
                                      <span>Duration</span>
                                    </div>
                                    <span className="font-weight-bold text-uppercase">
                                      {currentPeriod} year(s)
                                    </span>
                                  </h6>
                                  <hr className="w-100 mt-3 mb-4" />
                                </div>

                                <div className="item">
                                  <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                    <span>Domain name</span>
                                    {hasDomain === false ? (
                                      <span className="font-weight-bold text-uppercase">
                                        FREE
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </h6>
                                  <p className="card-text mb-0">
                                    {currentDomain !== null
                                      ? currentDomain
                                      : 'NAN'}
                                    {hasDomain === true && (
                                      <span className="ml-3">
                                        <span className="badge badge-success rounded-pill">
                                          Pre owned
                                        </span>
                                      </span>
                                    )}
                                  </p>
                                  <hr className="w-100 mt-3 mb-4" />
                                </div>

                                <div className="total">
                                  <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                    <span>Total</span>
                                    <span className="font-weight-bold text-uppercase">
                                      {new Intl.NumberFormat('en-US').format(
                                        filteredProduct !== null &&
                                          default_currency !== undefined
                                          ? ((12 *
                                              filteredProduct.renewal_price +
                                              24 *
                                                filteredProduct.renewal_price *
                                                (parseInt(currentPeriod) - 1)) /
                                              2) *
                                              (default_currency.code !== 'FCFA'
                                                ? parseFloat(
                                                    default_currency.rate
                                                  )
                                                : 1)
                                          : 0
                                      )}{' '}
                                      {default_currency !== undefined &&
                                        default_currency.code}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            ))}
                      </div>
                    )}
                  {order &&
                    order.draft !== undefined &&
                    !order.draft.services.hasOwnProperty('1') && (
                      <div className="card">
                        {domainDetails !== undefined &&
                          domainDetails !== null &&
                          domainDetails.tld === dProduct.name &&
                          default_currency !== undefined && (
                            <div
                              className="card-body pb-4 pt-4"
                              key={domainDetails.product_id}
                            >
                              <h6 className="text-left text-uppercase font-weight-bold mb-3">
                                Order Summary
                              </h6>
                              <hr className="w-100 mb-4 m-0" />

                              <div className="item">
                                <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                  <div>
                                    <span>
                                      {domainDetails !== null
                                        ? domainDetails.name
                                        : 'NAN'}
                                    </span>
                                  </div>

                                  <span className="font-weight-bold text-uppercase">
                                    {new Intl.NumberFormat('en-US').format(
                                      domainDetails !== null &&
                                        dProduct !== null &&
                                        default_currency !== undefined
                                        ? dProduct.register *
                                            parseInt(currentPeriod) *
                                            (default_currency.code !== 'FCFA'
                                              ? parseFloat(
                                                  default_currency.rate
                                                )
                                              : 1)
                                        : 0
                                    )}{' '}
                                    {default_currency !== undefined &&
                                      default_currency.code}
                                  </span>
                                </h6>

                                <hr className="w-100 mt-3 mb-4" />
                              </div>

                              <div className="item">
                                <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                  <div>
                                    <span>Duration</span>
                                  </div>
                                  <span className="font-weight-bold text-uppercase">
                                    {currentPeriod} year(s)
                                  </span>
                                </h6>

                                <hr className="w-100 mt-3 mb-4" />
                              </div>

                              <div className="total">
                                <h6 className="card-subtitle mb-2 d-flex justify-content-between">
                                  <span>Total</span>
                                  <span className="font-weight-bold text-uppercase">
                                    {new Intl.NumberFormat('en-US').format(
                                      domainDetails !== null &&
                                        dProduct !== null &&
                                        default_currency !== undefined
                                        ? dProduct.register *
                                            parseInt(currentPeriod) *
                                            (default_currency.code !== 'FCFA'
                                              ? parseFloat(
                                                  default_currency.rate
                                                )
                                              : 1)
                                        : 0
                                    )}{' '}
                                    {default_currency !== undefined &&
                                      default_currency.code}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  {order &&
                    order.draft !== undefined &&
                    !order.draft.services.hasOwnProperty('1') &&
                    domainDetails !== undefined &&
                    domainDetails !== null &&
                    dProduct.hasOwnProperty('name') &&
                    domainDetails.tld === dProduct.name &&
                    default_currency !== undefined &&
                    client_details.hasOwnProperty('id') &&
                    _isLoading !== true &&
                    canPay === true && (
                      <button
                        type="button"
                        onClick={() => {
                          setPaymentLoader(!paymentLoader);
                          handleFlutterPayment({
                            callback: (response) => {
                              handlePayment(response);
                              setPaymentLoader(false);
                              closePaymentModal(); // this will close the modal programmatically
                            },
                            onClose: () => {},
                          });
                        }}
                        className="btn btn-primary btn-round btn-lg w-100"
                      >
                        {paymentLoader ? (
                          <CircularProgress className={classes.loaderStyle} />
                        ) : (
                          'Pay now'
                        )}
                      </button>
                    )}

                  {order &&
                    order.draft !== undefined &&
                    order.draft.services.hasOwnProperty('1') &&
                    products.product !== undefined &&
                    products.product.length > 0 &&
                    client_details.hasOwnProperty('id') &&
                    _isLoading !== true &&
                    canPay === true && (
                      <button
                        type="button"
                        onClick={() => {
                          setPaymentLoader(!paymentLoader);
                          handleFlutterPayment({
                            callback: (response) => {
                              handlePayment(response);
                              setPaymentLoader(false);
                              closePaymentModal(); // this will close the modal programmatically
                            },
                            onClose: () => {},
                          });
                        }}
                        className="btn btn-primary btn-round btn-lg w-100"
                      >
                        {paymentLoader ? (
                          <CircularProgress className={classes.loaderStyle} />
                        ) : (
                          'Pay now'
                        )}
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
